import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { loadSuperagent } from "../lib/async-bundles";
import { FILTER_UPDATED, FILTER_FORM_UPDATED, DOM_UPDATED, UPDATE_GLOBE_ITEMS, HOVER_LIST_UPDATED, FINISH_LOADER, START_LOADER } from '../lib/events';
import Components from '../core/Components';

export default (el, props) => {
    const $el = $(el);
    const $paginationWrapper = $el.find('[data-pagination-wrapper]');
    const $resultSummary = $el.find('[data-filtered-result-summary]');

    const { indexPageUrl, contentTypeLabel } = props;

    let isLoading = false;
    let lastFilter = null;
    let hasUpdatedHistory = false;
    let superagent = null;
    let paginationObserver;

    const init = () => {
        loadSuperagent(loadedSuperagent => {
            superagent = loadedSuperagent;

            Dispatch.on(FILTER_UPDATED, onFilterUpdated);

            $paginationWrapper.on('click', 'a', e => {
                e.preventDefault();
                e.stopImmediatePropagation();
                loadNextPage();
            });

            paginationObserver = new IntersectionObserver(([{ isIntersecting }]) => {
                if (!isIntersecting) {
                    return;
                }

                maybeLoadMore();
            });

            paginationObserver.observe($paginationWrapper.get(0));
        });

        window.history.replaceState({ reloadBack: true }, document.title);
    };

    const destroy = () => {
        Dispatch.off(FILTER_UPDATED, onFilterUpdated)
        $paginationWrapper.off('click');
        
        if (paginationObserver) {
            paginationObserver.disconnect();
            paginationObserver = null;
        }
    };

    const onFilterUpdated = (e, data) => {
        doUpdate(data.filter);
    };

    const doUpdate = filter => {
        isLoading = true;
        $resultSummary.text('');
        $el.css({ opacity: 0.3, 'pointer-events': 'none' });
        
        if (superagent === null) {
            console.error('Superagent bundle not loaded');
            return;
        }
        
        Dispatch.emit(START_LOADER);

        superagent
            .get(indexPageUrl)
            .query({ ajax: 'yes' })
            .set({ 'X-Requested-With': 'XMLHttpRequest' })
            .query(filter)
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }

                insertData(text, false, true);

                if (window.history) {
                    const newUrl = indexPageUrl + (filter !== '' ? (indexPageUrl.indexOf('?') !== -1 ? '&' : '?') + filter : '');

                    if (hasUpdatedHistory) {
                        window.history.replaceState({ reloadBack: true }, document.title, newUrl);
                    } else {
                        window.history.pushState({ reloadBack: true }, document.title, newUrl);
                    }

                    hasUpdatedHistory = true;
                }
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
                $el.css({ opacity: 1, 'pointer-events': '' });
            });

        lastFilter = filter;
    };

    const loadNextPage = () => {
        if (isLoading) {
            return;
        }

        isLoading = true;
        $resultSummary.text('');

        const $link = $paginationWrapper.find('a');
        const url = $link.attr('href');

        if (superagent === null) {
            console.error('Superagent bundle not loaded');
            return;
        }

        $paginationWrapper.css({ opacity: 0.5 });
        
        superagent
            //.get(url + (url.indexOf('?') !== -1 ? '&' : '?') + 'ajax=yes')
            .get(url)
            .query({ ajax: 'yes' })
            .set({ 'X-Requested-With': 'XMLHttpRequest' })
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                insertData(text, true, false);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
            });
    };
    
    const maybeLoadMore = () => {
        if (isLoading) {
            return;
        }
        
        const $nextLink = $paginationWrapper.find('a');
        
        if ($nextLink.length > 0) {
            $nextLink.get(0).click();
        }
    };

    const insertData = (html, append, updateFilter) => {
        //console.log('insertData', append);
        $paginationWrapper.css({ opacity: '' });

        const $newHtml = $(`<div>${html}</div>`);
        $newHtml.find('noscript').remove();

        const $newResultsList = $newHtml.find('[data-results-list]');
        const $newPaginationWrapper = $newHtml.find('[data-pagination-wrapper]');
        const $form = $newHtml.find('[data-filter-form]');
        const $resultsList = $el.find('[data-results-list]');
        const $resultsTarget = $el.find('[data-results-list] ul');

        if ($newResultsList.find('[data-component="ProjectsGlobe"]').length > 0 && $resultsList.find('[data-component="ProjectsGlobe"]').length > 0) { /* There is already a globe */
            const newData = JSON.parse($newResultsList.find('[data-component="ProjectsGlobe"]').attr('data-component-props'));
            Dispatch.emit(UPDATE_GLOBE_ITEMS, newData);
            Dispatch.emit(FINISH_LOADER);
            
            $resultSummary.text('Updated globe, showing ' + (newData.items !== undefined ? newData.items.length : 0) + ' projects')
        } else { /* Normal stuff */
            if ($newResultsList.length) {
                $resultsList.attr('class', $newResultsList.attr('class'));

                if (append) {
                    $newResultsList.find('[data-results-list-header], [data-table-header]').remove();
                } else {
                    Components.destroy($resultsList);
                    $resultsList.empty();
                }

                // We do this before actually inserting them, to get to the items before they 
                // are detached from the $newResultsList DOM
                Dispatch.emit(DOM_UPDATED, { $markup: $newResultsList });

                const count = $newResultsList.find('li').length;
                
                if (append) {
                    $resultSummary.text('Appended ' + count + ' new ' + contentTypeLabel + ' to list.')
                    $resultsTarget.append($newResultsList.find('li').nodes);
                } else {
                    $resultSummary.text('Showing ' + count + ' ' + contentTypeLabel + ' in list.')
                    $resultsList.append($newResultsList.get(0).children);
                }
            } else {
                // TODO : Error?
            }

            if ($newPaginationWrapper.length > 0) {
                const $resultsList = $el.find('[data-pagination-wrapper]');
                $resultsList.empty().append($newPaginationWrapper.get(0).children);
            }
            
            Dispatch.emit(FINISH_LOADER);            
            Dispatch.emit(HOVER_LIST_UPDATED, { });
        }

        if (updateFilter && $form.length > 0) {
            Dispatch.emit(FILTER_FORM_UPDATED, { $form });
        }

        Components.init();
    };

    return {
        init,
        destroy
    };

};
