import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import serialize from 'form-serialize';
import Dispatch from '../core/Dispatch';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { FILTER_UPDATED, FILTER_FORM_UPDATED } from '../lib/events';

import { loadGsap } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    const $filtersWrap = $el.find('[data-filters-wrap]');
    const $resetButton = $el.find('[data-reset-button]');

    const { defaultFormValueString = '' } = props || {};

    let $openPanel = null;
    let gsap = null;
    let filterIsOpen = false;

    const init = () => {
        if (UserPreferencesHandler.shouldAnimate()) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });
            });
        }
        
        $el.on('click', 'button[data-toggle-filter-button]', e => {
            e.preventDefault();
            toggleFilter(e.triggerTarget);
        });


        $el.on('click', '.filter-panel h3 button', e => {
            e.preventDefault();
            togglePanel(e.triggerTarget);
        });

        $el.on('click', 'button[data-remove-term-group]', e => {
            e.preventDefault();
            removeTerm(e.triggerTarget);
        });

        $el.on('change', 'input[type="checkbox"],input[type="radio"]', e => {
            e.preventDefault();
            submit();
        });

        $el.on('submit', e => {
            e.preventDefault();
            submit();
        });

        $resetButton.on('click', onResetClick);

        document.addEventListener('keyup', onKeyUp);

        Dispatch.on(FILTER_FORM_UPDATED, onFormUpdated);
    };

    const destroy = () => {
        $el.off('click');
        $el.off('change');
        $el.off('submit');
        $resetButton.off('click');

        document.removeEventListener('keyup', onKeyUp);

        Dispatch.off(FILTER_FORM_UPDATED, onFormUpdated);
    };

    const onKeyUp = e => {
        const key = e.keyCode || e.which;

        if ($openPanel && key === 27) {
            adjustWrapperHeight(null, $openPanel);
            closeOpenPanel();

            const $btn = $openPanel.find('h3 button');
            $btn.get(0).focus();

            $openPanel = null;
        }
    };

    const submit = () => {
        const filter = serialize($el.get(0), { hash: false });
        const filterArray = serialize($el.get(0), { hash: true });
        Dispatch.emit(FILTER_UPDATED, { filter, filterArray })
        checkReset(filter);
    };
    
    const toggleFilter = triggerTarget => {
        const $btn = $(triggerTarget);
        const openText = $btn.data('show-filter-label');
        const closeText = $btn.data('hide-filter-label');
        
        if (filterIsOpen) {
            if (gsap) {
                gsap.to($filtersWrap.get(0), { duration: 0.2, height: 0, opacity: 0, ease: 'sine.out', onComplete: () => {
                    $filtersWrap.css({ display: 'none' });
                } });
                
            } else {
                $filtersWrap.css({ display: 'none' });
            }
        } else {
            if (gsap) {
                $filtersWrap.css({ display: '', height: '' });
                const targetHeight = $filtersWrap.height();
                $filtersWrap.css({ height: 0, opacity: 0 });
                
                gsap.to($filtersWrap.get(0), { duration: 0.5, height: targetHeight, ease: 'quint.out', onComplete: () => {
                    $filtersWrap.css({ height: '' });
                } });
                
                gsap.to($filtersWrap.get(0), { duration: 0.3, delay: 0.2, opacity: 1, ease: 'sine.out' });
            } else {
                $filtersWrap.css({ display: '' });
            }
        }
        
        filterIsOpen = !filterIsOpen;

        $btn.attr('aria-expanded', filterIsOpen ? 'true' : 'false');
        $btn.find('[data-button-text]').text(filterIsOpen ? closeText : openText)
    };

    const togglePanel = triggerTarget => {
        const $panel = $(triggerTarget.closest('.filter-panel'));

        adjustWrapperHeight($panel, $openPanel);

        if ($openPanel) {
            const isSamePanel = $panel.get(0) === $openPanel.get(0);

            closeOpenPanel();

            if (isSamePanel) {
                $openPanel = null;
                return;
            }
        }

        // open the panel
        if ($panel.length > 0) {
            const $fieldset = $panel.find('fieldset');
            const $selected = $panel.find('.filter-panel__selected');
            const $btn = $panel.find('h3 button');

            if (gsap) {
                const hasSelected = $selected.find('button').length > 0;

                if (hasSelected) {
                    gsap.to($selected.get(0), {
                        duration: 0.2, opacity: 0, onCompleted: () => {
                            $selected.attr('hidden', '');
                            $fieldset.css({ opacity: 0 }).attr('hidden', null);
                            gsap.to($fieldset.get(0), { delay: 0.2, duration: 0.4, opacity: 1 });
                        }
                    });
                } else {
                    $selected.attr('hidden', '');
                    $fieldset.css({ opacity: 0 }).attr('hidden', null);
                    gsap.to($fieldset.get(0), { duration: 0.4, opacity: 1 });
                }
            } else {
                $fieldset.attr('hidden', null);
                $selected.attr('hidden', '');
            }

            $btn.attr('aria-expanded', 'true');
            $openPanel = $panel;
        }
    };

    const closeOpenPanel = () => {
        if ($openPanel && $openPanel.length > 0) {
            const $fieldset = $openPanel.find('fieldset');
            const $selected = $openPanel.find('.filter-panel__selected');
            const $btn = $openPanel.find('h3 button');

            if (gsap) {
                gsap.to($fieldset.get(0), {
                    duration: 0.1, opacity: 0, onComplete: () => {
                        $fieldset.attr('hidden', '');
                        $selected.css({ opacity: 0 }).attr('hidden', null);
                        gsap.to($selected.get(0), { delay: 0.1, duration: 0.4, opacity: 1 })
                    }
                })
            } else {
                $fieldset.attr('hidden', '');
                $selected.attr('hidden', null);
            }
            $btn.attr('aria-expanded', 'false');
        }
    };

    const adjustWrapperHeight = ($newPanel, $oldPanel) => {
        if (gsap) {
            const currentWrapperHeight = $filtersWrap.height();
            let newWrapperHeight;
            
            if ($newPanel === null && $oldPanel === null) {
                return;
            }

            if ($newPanel !== null && $oldPanel === null) {
                const $newFieldSet = $newPanel.find('fieldset');
                const $newSelected = $newPanel.find('.filter-panel__selected');

                $newFieldSet.attr('hidden', null);
                $newSelected.attr('hidden', '');

                newWrapperHeight = $filtersWrap.height();

                $newFieldSet.attr('hidden', '');
                $newSelected.attr('hidden', null);
            } else if (($newPanel === null && $oldPanel !== null) || ($oldPanel !== null && $newPanel.get(0) === $oldPanel.get(0))) {
                const $oldFieldSet = $oldPanel.find('fieldset');
                const $oldSelected = $oldPanel.find('.filter-panel__selected');

                $oldFieldSet.attr('hidden', '');
                $oldSelected.attr('hidden', null);

                newWrapperHeight = $filtersWrap.height();

                $oldFieldSet.attr('hidden', null);
                $oldSelected.attr('hidden', '');
            } else {
                const $newFieldSet = $newPanel.find('fieldset');
                const $newSelected = $newPanel.find('.filter-panel__selected');
                const $oldFieldSet = $oldPanel.find('fieldset');
                const $oldSelected = $oldPanel.find('.filter-panel__selected');

                $oldFieldSet.attr('hidden', '');
                $oldSelected.attr('hidden', null);
                $newFieldSet.attr('hidden', null);
                $newSelected.attr('hidden', '');

                newWrapperHeight = $filtersWrap.height();

                $oldFieldSet.attr('hidden', null);
                $oldSelected.attr('hidden', '');
                $newFieldSet.attr('hidden', '');
                $newSelected.attr('hidden', null);
            }

            if (currentWrapperHeight !== newWrapperHeight) {
                gsap.set($filtersWrap.get(0), { height: currentWrapperHeight });
                gsap.to($filtersWrap.get(0), {
                    duration: 0.6, height: newWrapperHeight, ease: 'quint.out', onComplete: () => {
                        // make sure this is after panels have animated out/in
                        gsap.set($filtersWrap.get(0), { height: '' });
                    }
                });
            }
        }
    };

    const removeTerm = triggerTarget => {
        const $triggerTarget = $(triggerTarget);
        const group = $triggerTarget.data('remove-term-group');
        const value = $triggerTarget.data('remove-term-value');

        if (group && value) {
            const $input = $('input[name="' + group + '[]"][value="' + value + '"]');

            if ($input.length > 0) {
                $input.get(0).click();
                triggerTarget.closest('li').remove();
            }
        }
    };

    const onFormUpdated = (key, data) => {
        if (data.$form) {
            const $filterPanels = data.$form.find('[data-filter-panel]');

            $filterPanels.each(panel => {
                const $panel = $(panel);
                const prop = $panel.data('filter-panel');

                if (prop) {
                    const $existingPanel = $el.find('[data-filter-panel="' + prop + '"]');

                    if ($existingPanel.length > 0) {
                        $existingPanel.find('.filter-panel__selected').html($panel.find('.filter-panel__selected').html());
                    }
                }
            });

            const filter = serialize($el.get(0), { hash: false });
            checkReset(filter);
        }
    };

    const checkReset = filter => {
        // remove view mode from string
        const regexp = /view=(list|grid|map)&/i;
        filter = filter.replace(regexp, '');

        if (filter !== defaultFormValueString) {
            $resetButton.removeClass('hidden').addClass('flex');
        } else {
            $resetButton.addClass('hidden').removeClass('flex');
        }
    };

    const onResetClick = e => {
        e.preventDefault();

        $el.find('input').each(input => {
            const $input = $(input);

            if ($input.data('should-persist') !== 1) {
                $input.get(0).checked = false;
                $input.attr('checked', null);
            }

            if ($input.data('is-default') === 1) {
                $input.get(0).checked = true;
                $input.attr('checked', '');
            }
        });

        adjustWrapperHeight(null, $openPanel);
        closeOpenPanel();
        submit();
    };

    return {
        init,
        destroy
    };
};
