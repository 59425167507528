import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { loadGsap } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    const $menuToggle = $el.find('[data-menu-toggle]');
    const $menu = $el.find('#menu');

    const { labelToggleClose, labelToggleOpen } = props;

    let menuIsOpen = false;
    let focusedElement = null;
    let gsap = null;

    const init = () => {
        const navId = $menuToggle.attr('href');

        $menuToggle.attr({
            tabIndex: '0',
            role: 'button',
            'aria-expanded': 'false'
        }).on('click', e => {
            e.preventDefault();
            toggleMenu(false);
        }).on('keydown', e => {
            const key = e.key || e.keyCode || e.which || null;
            if (['Enter', 13].indexOf(key) > -1) {
                e.preventDefault();
                toggleMenu(false);
            }
        }).get(0).removeAttribute('href');

        $menu.on('click', e => {
            if (menuIsOpen && e.target === e.triggerTarget) {
                closeMenu();
            }
        });
        
        $menu.find('a').on('click', e => {
            if (menuIsOpen) {
                closeMenu(true);
            }
        });
        
        $el.find('[data-logo]').on('click', e => {
            if (menuIsOpen) {
                closeMenu(true);
            }
        });
        
        document.addEventListener('keyup', onKeyUp);

        // Account for the menu being opened already before the JS had the chance to boot
        requestAnimationFrame(() => {
            if (navId && window.location.hash === navId) {
                window.location.hash = '';
                openMenu(true);
                if (window.history && window.history.replaceState) {
                    window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                }
            }
        });

        if (UserPreferencesHandler.shouldAnimate()) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });
            });
        }
    };

    const destroy = () => {
        $menuToggle.off('click').off('keydown');
        document.removeEventListener('keyup', onKeyUp);
    };

    const onKeyUp = e => {
        const key = e.keyCode || e.which;

        if (menuIsOpen && key === 27) {
            closeMenu();
        }
    };

    const toggleMenu = forced => {
        if (menuIsOpen) {
            closeMenu(forced);
        } else {
            openMenu(forced);
        }
    };

    const openMenu = forced => {
        $menu.css({ display: 'flex' });

        if (UserPreferencesHandler.shouldAnimate() && gsap !== null) {
            gsap.set($menu.get(0), { opacity: 0 });
            gsap.to($menu.get(0), { duration: forced ? 0 : 0.2, opacity: 1 });
        }

        $menuToggle.text(labelToggleClose).get(0).setAttribute('aria-expanded', 'true');
        focusedElement = document.activeElement || null;
        Viewport.lockTabbing($el.get(0), $menuToggle.get(0));

        menuIsOpen = true;
    };

    const closeMenu = forced => {
        if (UserPreferencesHandler.shouldAnimate() && gsap !== null) {
            gsap.to($menu.get(0), {
                duration: forced ? 0 : 0.1, opacity: 0, onComplete: () => {
                    $menu.css({ display: '' });
                }
            });
        } else {
            $menu.css({ display: '' });
        }

        Viewport.releaseTabbing(focusedElement);
        focusedElement = null;
        $menuToggle.text(labelToggleOpen).get(0).setAttribute('aria-expanded', 'false');

        menuIsOpen = false;
    };

    return {
        init,
        destroy
    };

};
