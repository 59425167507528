import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';
import Dispatch from '../core/Dispatch';

import { USER_PREFERENCES_UPDATED } from '../lib/events';

import { loadImagePathClass } from '../lib/async-bundles';

export default (el, props) => {
    const $el = $(el);
    const { imagesFull, imagesLowres } = props;
    
    const $imagePathWrapper = $el.find('[data-image-area]');
    
    let IPClass = null;
    let imagePath = null;
    let prefs = null;

    const init = () => {
        prefs = UserPreferencesHandler.getPrefs();
        
        if (prefs.simplified !== 'on') {
            loadImagePathClass(loaded => {
                IPClass = loaded.default;
                initImagePath();
            });
        } else {
            // TODO : What to do?
        }
        
        Dispatch.on(USER_PREFERENCES_UPDATED, onPrefsUpdated);
    };

    const destroy = () => {

    };
    
    const initImagePath = () => {
        prefs = UserPreferencesHandler.getPrefs();
        
        imagePath = new IPClass($imagePathWrapper.get(0), prefs.lowres === 'on' ? imagesLowres : imagesFull);
    };

    const onPrefsUpdated = () => {
        const newPrefs = UserPreferencesHandler.getPrefs();
        
        if (imagePath && newPrefs.lowres !== prefs.lowres) {
            imagePath.setURLs(newPrefs.lowres === 'on' ? imagesLowres : imagesFull);
        }
        
        prefs = newPrefs;
    };
    
    return {
        init,
        destroy
    };
};
