import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { loadGsap } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    const $toggleButton = $el.find('[data-expand-button]');
    const $expandableItems = $el.find('[data-expand-visibility="expanded"]');

    const { labelToggleClose, labelToggleOpen } = props;

    let isExpanded = false;
    let gsap = null;

    const init = () => {
        if (UserPreferencesHandler.shouldAnimate()) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });
            });
        }
        
        $toggleButton.on('click', onToggle);
    };

    const destroy = () => {
        $toggleButton.off('click');
    };
    
    const onToggle = e => {
        e.preventDefault();
        
        if (isExpanded) {
            hide();
            $toggleButton.attr('aria-expanded', 'false').find('[data-button-text]').text(labelToggleOpen);
            isExpanded = false;
        } else {
            show();
            $toggleButton.attr('aria-expanded', 'true').find('[data-button-text]').text(labelToggleClose);
            isExpanded = true;
        }
    };
    
    const show = () => {
        if (gsap) {
            $expandableItems.css({ display: '', height: 0, opacity: 0, overflow: 'hidden' });
            gsap.to($expandableItems.nodes, { duration: 0.5, ease: 'quart.out', height: 'auto' });
            gsap.to($expandableItems.nodes, { duration: 1, delay: 0.2, ease: 'sine.out', opacity: 1, stagger: 0.02 });
        } else {
            $expandableItems.css({ display: '' });
        }
    };

    const hide = () => {
        if (gsap) {
            //$expandableItems.css({ display: '', height: 0 });
            
            gsap.to($expandableItems.nodes, { duration: 0.1, ease: 'linear', opacity: 0 });
            gsap.to($expandableItems.nodes, { duration: 0.3, delay: 0.05, ease: 'sine.out', height: 0, onComplete: () => {
                    $expandableItems.css({ display: 'none', height: '' });
            } });
        } else {
            $expandableItems.css({ display: 'none' });
        }
    };

    return {
        init,
        destroy
    };
};
