import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { loadGsap } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    const $toggleButton = $el.find('[data-expand-button]');
    const $expandable = $el.find('[data-expandable');

    const { labelToggleClose, labelToggleOpen } = props;

    let isExpanded = false;
    let gsap = null;

    const init = () => {
        if (UserPreferencesHandler.shouldAnimate()) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });
            });
        }

        $toggleButton.on('click', onToggle);
    };

    const destroy = () => {
        $toggleButton.off('click');
    };

    const onToggle = e => {
        e.preventDefault();
        const $buttonText = $toggleButton.find('[data-button-text]');

        if (isExpanded) {
            if (gsap) {
                gsap.to($expandable.get(0), {
                    duration: 0.1, opacity: 0, onComplete: () => {
                        gsap.to($expandable.get(0), {
                            duration: 0.6, ease: 'quart.out', height: 0, onComplete: () => {
                                gsap.set($expandable.get(0), { height: '', opacity: '', display: 'none' });
                                gsap.to($expandable.get(0), { duration: 0.4, opacity: 1 });

                            }
                        });

                        gsap.to($buttonText.get(0), {
                            duration: 0.1, opacity: 0, onComplete: () => {
                                $buttonText.text(labelToggleOpen);
                                gsap.to($buttonText.get(0), { duration: 0.1, opacity: 1 });
                            }
                        })
                    }
                });

            } else {
                $expandable.css({ display: 'none' });
                $toggleButton.find('[data-button-text]').text(labelToggleOpen);
            }

            $toggleButton.attr('aria-expanded', 'false');
            isExpanded = false;
        } else {
            if (gsap) {
                $expandable.css({ display: '' });
                const targetHeight = $expandable.height();
                
                gsap.set($expandable.get(0), { height: 0, opacity: 0 });
                gsap.to($expandable.get(0), {
                    duration: 0.6, ease: 'quart.out', height: targetHeight, onComplete: () => {
                        gsap.set($expandable.get(0), { height: '' });
                        gsap.to($expandable.get(0), { duration: 0.8, opacity: 1 });
                    }
                });
                gsap.to($buttonText.get(0), {
                    duration: 0.1, opacity: 0, onComplete: () => {
                        $buttonText.text(labelToggleClose);
                        gsap.to($buttonText.get(0), { duration: 0.1, opacity: 1 });
                    }
                })
            } else {
                $expandable.css({ display: '' });
                $toggleButton.find('[data-button-text]').text(labelToggleClose);
            }

            $toggleButton.attr('aria-expanded', 'true');
            isExpanded = true;
        }
    };

    return {
        init, destroy
    };
};
