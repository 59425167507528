import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';
import Dispatch from '../core/Dispatch';

import { UPDATE_GLOBE_ITEMS } from '../lib/events';
import { loadGlobe } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    const $globeWrapper = $el.find('[data-globe-wrapper]');
    
    const { items } = props;
    
    let Snowglobe = null;
    let globe = null;
    
    const init = () => {
        const prefs = UserPreferencesHandler.getPrefs();

        if (prefs.simplified !== 'on') {
            loadGlobe(loaded => {
                Snowglobe = loaded.Snowglobe;
                globe = new Snowglobe($globeWrapper.get(0), items);
            });
            
            Dispatch.on(UPDATE_GLOBE_ITEMS, onUpdateGlobeItems);
        } else {
            // TODO : What to do?
            /*
            $el.find('[data-fallback-image]').removeClass('hidden');
            $modelWrapper.addClass('hidden');
             */
        }
    };

    const destroy = () => {
        if (globe) {
            // should probably have a destroy on the snowglobe
        }
        Dispatch.off(UPDATE_GLOBE_ITEMS);
    };
    
    const onUpdateGlobeItems = (key, data) => {
        if (globe && data !== undefined && data.items !== undefined) {
            globe.updateLocations(data.items, 10);
        }
    };
    
    return {
        init,
        destroy
    };

};
