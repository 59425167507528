import $ from '../core/Dom';

export default (el, props) => {
    const $el = $(el);

    const init = () => {
        $el.on('change', 'input[type="checkbox"],input[type="radio"]', e => {
            e.preventDefault();
            submit();
        });
    };

    const destroy = () => {
        $el.off('change');
    };

    const submit = () => {
        el.submit();
    };

    return {
        init,
        destroy
    };
};
