import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import { FLICKITY_DRAG_MOVE } from "../lib/events";

import { loadFlickity } from "../lib/async-bundles";

export default el => {
    const SLIDER_SELECTOR = '[data-slideshow-slider]';
    const SLIDE_SELECTOR = '[data-slideshow-slide]';
    const NAV_SELECTOR = '[data-slideshow-nav]';

    const $el = $(el);
    const $slider = $el.find(SLIDER_SELECTOR);
    const $slides = $el.find(SLIDE_SELECTOR);
    const $navButtons = $el.find(NAV_SELECTOR);

    let Flickity;
    let slideshow;
    let stageW;

    const init = () => {
        loadFlickity(flickity => {
            Flickity = flickity;
            initFlickity();
            Viewport.on('resize', onResize);
        });
    };

    const destroy = () => {
        if (slideshow) {
            destroyFlickity();
            Viewport.off('resize', onResize);
        }
    };

    const initFlickity = () => {

        destroyFlickity();

        $el.addClass('js-has-flickity');

        $slider.css({
            height: `${$slider.height()}px`
        });

        let nextPos = 0;

        $slides.each((item, index) => {
            const $item = $(item);
            $item.css({
                width: $item.width(),
                height: $item.height(),
                top: 0,
                left: nextPos,
                position: 'absolute'
            });

            nextPos += $item.width();
        });

        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            groupCells: true,
            resize: false,
            wrapAround: false,
            freeScroll: true,
            contain: true,
            accessibility: true
        });

        slideshow.on('dragStart', () => slideshow.slider.style.pointerEvents = 'none');
        slideshow.on('dragEnd', () => slideshow.slider.style.pointerEvents = 'auto');
        slideshow.on('dragMove', onDragMove);
        slideshow.on('change', checkButtonStatus);
        slideshow.on('select', checkButtonStatus);
        
        if ($navButtons.length > 0) {
            $navButtons.on('click', onNavButtonClick);
        }
    };

    const destroyFlickity = () => {
        if (!slideshow) {
            return;
        }
        slideshow.off('change');
        slideshow.destroy();
        slideshow = null;

        $slider.attr('style', '');
        $slides.attr('style', '');

        $el.removeClass('js-has-flickity');
    };

    const onResize = () => {
        const vw = Viewport.width;
        if (!slideshow || vw === stageW) {
            return;
        }
        stageW = vw;
        initFlickity();
    };

    const onDragMove = e => {
        Dispatch.emit(FLICKITY_DRAG_MOVE, { data: e });
    };

    const onNavButtonClick = e => {
        e.preventDefault();

        if (slideshow) {
            const $button = $(e.triggerTarget);

            if ($button.data('slideshow-nav') === 'previous') {
                slideshow.previous();
            } else if ($button.data('slideshow-nav') === 'next') {
                slideshow.next();
            }
        }
    };

    const checkButtonStatus = () => {
        if (slideshow) {
            const $nextBtn = $navButtons.filter('[data-slideshow-nav="next"]');
            const $prevBtn = $navButtons.filter('[data-slideshow-nav="previous"]');
            
            // Check if at start
            if (slideshow.selectedIndex === 0) {
                $prevBtn.attr("disabled", "disabled").addClass('text-grey-1');
            } else {
                $prevBtn.attr("disabled", null).removeClass('text-grey-1');
            }
    
            // Check if at end
            if (slideshow.selectedIndex === slideshow.slides.length - 1) {
                $nextBtn.attr("disabled", "disabled").addClass('text-grey-1');
            } else {
                $nextBtn.attr("disabled", null).removeClass('text-grey-1');
            }
        }
    };

    return {
        init,
        destroy
    };

};
