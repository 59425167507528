import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import UserPreferencesHandler from './UserPreferencesHandler';

import {
    loadGsap,
    loadFlickity, 
    loadUnderPiece, 
    loadOperaenPiece 
} from './async-bundles';

import { SlideshowAnimator } from './SlideshowAnimator';

const $body = $('body');

let $wrapper = $body.find('[data-gallery-viewer]');
let $inner = $body.find('[data-gallery-viewer-inner]');
let $muteBtn = $body.find('[data-gallery-mute-btn]');

let $sourceImages = null;
let pieceId = '';
let isStandalonePlayer = false;
let isOpen = false;
let gsap = null;
let Flickity = null;
let slideshow = null;
let animator = null;

let soundPieceDeactivate = null;

const init = () => {
    $wrapper.on('click', '[data-gallery-close-btn]', e => {
        e.preventDefault();
        close();
    });

    $muteBtn.on('click', onMute);

    Viewport.on('resize', onResize);
    document.addEventListener('keyup', onKeyUp);
};

const onResize = () => {
    if (isOpen) {
        $inner.find('img').each(item => {
            const $image = $(item);
            $image.css({ height: $wrapper.height(), width: $wrapper.height() * $image.attr('data-aspectratio') });
        });

        if (slideshow) {
            slideshow.resize();
        }
    }
};

const onKeyUp = e => {
    const key = e.keyCode || e.which;

    if (!isStandalonePlayer && isOpen && key === 27) {
        close();
    }
};


const show = ($images, triggerPieceId, isPlayer) => {
    $sourceImages = $images;
    pieceId = triggerPieceId || '';
    isStandalonePlayer = isPlayer || false;
    soundPieceDeactivate = null;

    if (UserPreferencesHandler.shouldAnimate() && gsap === null) {
        loadGsap(({ default: loadedGsap }) => {
            gsap = loadedGsap;
            gsap.defaults({ overwrite: 'auto' });
            continueShow();
        });
    } else {
        continueShow();
    }
};

const continueShow = () => {
    $wrapper.removeClass('hidden').addClass('fixed');

    if (UserPreferencesHandler.shouldAnimate() && gsap !== null) {
        gsap.from($wrapper.get(0), { opacity: 0, duration: isStandalonePlayer ? 2 : 0.3, ease: 'sine.out' });
    }

    if (Flickity === null) {
        loadFlickity(flickity => {
            Flickity = flickity;
            buildGallery();
        });
    } else {
        buildGallery();
    }
};

const buildGallery = () => {
    $sourceImages.each(image => {
        const $image = $(image);
        const srcset = UserPreferencesHandler.getPrefs().lowres !== 'on' ? $image.attr('data-highres-srcset') : $image.attr('data-lowres-srcset');

        const baseSource = srcset.split(',')[0].split(' ')[0];

        const testImage = new Image();
        testImage.src = baseSource;
        testImage.crossOrigin = 'anonymous';
        testImage.srcset = srcset;
        testImage.sizes = '100vw';
        testImage.dataset.srcset = srcset;
        testImage.dataset.aspectratio = $image.attr('data-aspectratio');
        testImage.classList.add('lazyload');
        testImage.classList.add('lazypreload');
        testImage.classList.add('w-auto');
        testImage.classList.add('max-w-none');
        testImage.style['height'] = $wrapper.height() + 'px';
        testImage.style['width'] = Math.ceil($wrapper.height() * $image.attr('data-aspectratio')) + 'px';
        testImage.style['opacity'] = '0';
        
        if (UserPreferencesHandler.getPrefs().lowres === 'on') {
            testImage.style['image-rendering'] = 'pixelated';
        }
        
        /*
        const $galleryImage = $('<img crossorigin="anonymous">');
        $galleryImage.attr('src', baseSource);
        $galleryImage.attr('srcset', srcset);
        $galleryImage.attr('data-srcset', srcset);
        $galleryImage.attr('sizes', '100vw');
        $galleryImage.attr('data-aspectratio', $image.attr('data-aspectratio'));
        $galleryImage.css({ height: $wrapper.height(), width: $wrapper.height() * $image.attr('data-aspectratio'), opacity: 0 });
        $galleryImage.addClass('lazyload lazypreload w-auto max-w-none');

        if (UserPreferencesHandler.getPrefs().lowres === 'on') {
            $galleryImage.css({ 'image-rendering': 'pixelated' });
        }
        */
        $inner.append(testImage);
    });

    initFlickity();

    if (UserPreferencesHandler.shouldAnimate() && gsap !== null) {
        gsap.to($inner.find('img').nodes, { opacity: 1, stagger: 0.1, delay: 0.4, duration: 0.5, ease: 'sine.out' });
    } else {
        $inner.find('img').css({ opacity: 1 });
    }

    isOpen = true;
};

const initFlickity = () => {
    destroyFlickity();

    slideshow = new Flickity($inner.get(0), {
        pageDots: false,
        prevNextButtons: false,
        setGallerySize: false,
        resize: false,
        wrapAround: true,
        cellAlign: 'left',
        freeScroll: true,
        contain: true,
        accessibility: true
    });

    if (UserPreferencesHandler.shouldAnimate() && gsap !== null) {
        animator = new SlideshowAnimator(slideshow, gsap, {
            startTimeout: 2000,
            speed: 60,
        });
    
        if (pieceId !== '') {
            $muteBtn.css({ display: 'block' });

            if (pieceId === 'under') {
                loadUnderPiece(async Under => {
                    const [schedule, deactivate, setVolume] = await Under.attachPiece(
                        slideshow,
                        $muteBtn.get(0),
                        {
                            glasses: [
                                "/assets/static/pieces/under/vcsl-wine-glasses-slow/asharp4.mp3",
                                "/assets/static/pieces/under/vcsl-wine-glasses-slow/d5.mp3",
                                "/assets/static/pieces/under/vcsl-wine-glasses-slow/dsharp4.mp3",
                                "/assets/static/pieces/under/vcsl-wine-glasses-slow/fsharp4.mp3",
                            ],
                            background: "/assets/static/pieces/under/sounds/bg_exterior.mp3",
                            ocean: "/assets/static/pieces/under/sounds/ocean.mp3",
                        }
                    );
                    soundPieceDeactivate = deactivate;
                });
            }

            if (pieceId === 'operaen') {
                loadOperaenPiece(async Operaen => {
                    const [schedule, deactivate, setVolume] = await Operaen.attachPiece(
                        slideshow,
                        $muteBtn.get(0),
                        {
                            chorus: [
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/a4.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/a5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/asharp4.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/asharp5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/b4.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/b5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/c5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/c6.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/csharp5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/d5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/dsharp5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/e5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/f5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/fsharp5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/g4.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/g5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/gsharp4.mp3",
                                "/assets/static/pieces/operaen/samples/sso-chorus-female/gsharp5.mp3"
                            ],
                            oboe: [
                                "/assets/static/pieces/operaen/samples/sso-cor-anglais/b3.mp3",
                                "/assets/static/pieces/operaen/samples/sso-cor-anglais/b4.mp3",
                                "/assets/static/pieces/operaen/samples/sso-cor-anglais/d4.mp3",
                                "/assets/static/pieces/operaen/samples/sso-cor-anglais/d5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-cor-anglais/f3.mp3",
                                "/assets/static/pieces/operaen/samples/sso-cor-anglais/f4.mp3",
                                "/assets/static/pieces/operaen/samples/sso-cor-anglais/f5.mp3",
                                "/assets/static/pieces/operaen/samples/sso-cor-anglais/gsharp3.mp3",
                                "/assets/static/pieces/operaen/samples/sso-cor-anglais/gsharp4.mp3"
                            ],
                            clips: [
                                "/assets/static/pieces/operaen/sounds/doors.mp3",
                                "/assets/static/pieces/operaen/sounds/interior_01.mp3",
                                "/assets/static/pieces/operaen/sounds/interior_02.mp3",
                                "/assets/static/pieces/operaen/sounds/interior_03.mp3",
                                "/assets/static/pieces/operaen/sounds/maakeskrik.mp3",
                                "/assets/static/pieces/operaen/sounds/overgang.mp3",
                                "/assets/static/pieces/operaen/sounds/overgang_02.mp3",
                                "/assets/static/pieces/operaen/sounds/tram.mp3",
                                "/assets/static/pieces/operaen/sounds/trillekoffert.mp3",
                                "/assets/static/pieces/operaen/sounds/tuning.mp3"
                            ],
                        }
                    );

                    soundPieceDeactivate = deactivate;
                });
            }
        }
    }
};

const onMute = () => {

};

const destroyFlickity = () => {
    if (!slideshow) {
        return;
    }

    slideshow.destroy();
    slideshow = null;

    $inner.attr('style', '');
};

const close = () => {
    const cb = () => {
        $wrapper.removeClass('fixed').addClass('hidden').css({ opacity: '' });

        if (soundPieceDeactivate !== null) {
            soundPieceDeactivate();
            soundPieceDeactivate = null;
        }

        if (animator) {
            animator.pause();
        }

        destroyFlickity();
        $inner.empty();
    };

    if (UserPreferencesHandler.shouldAnimate() && gsap !== null) {
        gsap.to($wrapper.get(0), {
            duration: isStandalonePlayer ? 2 : 0.2, opacity: 0, onComplete: () => {
                cb();
            }
        });
    } else {
        cb();
    }
};

export default {
    init,
    show,
    close
};
