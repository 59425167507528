export const loadFlickity = require('bundle-loader?lazy&name=flickity!flickity');
export const loadYoutube = require('bundle-loader?lazy&name=[name]!youtube-player');
export const loadVimeo = require('bundle-loader?lazy&name=[name]!@vimeo/player');
export const loadGsap = require('bundle-loader?lazy&name=gsap!gsap');
export const loadSuperagent = require('bundle-loader?lazy&name=superagent!superagent');
export const loadModelViewer = require('bundle-loader?lazy&name=modelViewer!../snohetta/model-viewer/ModelViewer');
export const loadCanvas = require('bundle-loader?lazy&name=canvas!../snohetta/canvas/Canvas');
export const loadGlobe = require('bundle-loader?lazy&name=globe!../snohetta/globe/Snowglobe');
export const loadUnderPiece = require('bundle-loader?lazy&name=underpiece!../pieces/under/Under');
export const loadOperaenPiece = require('bundle-loader?lazy&name=operaenpiece!../pieces/operaen/Operaen');
export const loadImagePathClass = require('bundle-loader?lazy&name=imagepathclass!../snohetta/ImagePathClass');
