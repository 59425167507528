import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import MediaControl from '../lib/MediaControl';

const instances = [];

export default el => {
    const dom = $(el);
    const progress = dom.find('[data-progress]').get(0);
    const $progress = dom.find('[data-progress]');
    const scrubber = dom.find('[data-scrubber]');
    const time = dom.find('[data-time]');
    const button = dom.find('[data-button]');
    const iconPlay = button.find('[data-play]');
    const iconPause = button.find('[data-pause]');
    const waveformSvg = dom.find('[data-waveform-svg]');

    let audio;
    let playing = false;
    let loaded = false;
    let waveformBars = [];

    const padNumber = number => (number > 9 ? number : `0${number}`);

    const onScrub = e => {
        if (e.offsetX) {
            const percent = e.offsetX / scrubber.width();
            audio.currentTime = audio.duration * percent;
        } else {
            toggleState();
        }
    };

    const onStateChanged = e => {
        console.log(e);
        playing = e.type === 'play' || e.type === 'playing';
        if (playing) {
            MediaControl.play(audio);
        }
        iconPlay.toggleClass('hidden', playing);
        iconPause.toggleClass('hidden', !playing);
    };

    const onTimeUpdate = () => {
        const minutes = padNumber(Math.floor(audio.currentTime / 60));
        const seconds = padNumber(Math.floor(audio.currentTime - minutes * 60));
        time.text(`${minutes}:${seconds}`);
    };

    const updateProgress = () => {
        const percent = audio.currentTime / audio.duration;

        const numBars = Math.round(waveformBars.length * percent);

        for (let i = 0; i < waveformBars.length; i++) {
            waveformBars[i].setAttribute('style', i <= numBars ? 'opacity: 1' : 'opacity: 0.3');
        }
    };

    const onProgress = () => {
        if (playing) {
            updateProgress();
        }
        requestAnimationFrame(onProgress);
    };

    const onSeeked = () => {
        if (!playing) {
            updateProgress();
            audio.play();
        }
    };

    const onCanPlay = () => {
        if (!loaded || playing) {
            loaded = true;
            audio.play();
            requestAnimationFrame(onProgress);
        }
    };

    const toggleState = () => {
        if (playing) {
            audio.pause();
            button.text('Play');
        } else {
            audio.play();
            button.text('Pause');
        }
    };

    const initAudio = () => {
        audio = new Audio(dom.data('src'));
        audio.addEventListener('canplay', onCanPlay);
        audio.addEventListener('timeupdate', onTimeUpdate);
        audio.addEventListener('play', onStateChanged);
        audio.addEventListener('playing', onStateChanged);
        audio.addEventListener('pause', onStateChanged);
        audio.addEventListener('ended', onStateChanged);
        audio.addEventListener('seeked', onSeeked);

        scrubber.on('click', onScrub);
        button.on('click', toggleState);
        MediaControl.addPlayer(audio);
        button.text('Pause');
    };

    const onResize = () => {
        drawWaveform();
    };

    const onLoadAudio = () => {
        scrubber.off('click', onLoadAudio);
        button.off('click', onLoadAudio);
        initAudio();
    };

    const drawWaveform = () => {
        waveformBars = [];
        waveformSvg.empty();

        const waveformWidth = $progress.width();
        const waveformHeight = $progress.height();

        waveformSvg.attr('width', waveformWidth);
        waveformSvg.attr('height', waveformHeight);

        const spacing = Viewport.breakpoint.size >= 750 ? 5 : 4;
        const numBars = Math.floor($progress.width() / spacing);

        for (let i = 0; i < numBars; i += 1) {
            const x = (i * spacing) + 1;
            const height = waveformHeight * (Math.random() * 0.8);

            const newpath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            newpath.setAttribute('d', `M ${x} ${waveformHeight} V ${height}`);
            newpath.setAttribute('stroke', 'currentColor');
            newpath.setAttribute('stroke-width', '1');
            newpath.setAttribute('style', 'opacity: 0.3;');
            newpath.setAttribute('class', 'transition-opacity duration-100');
            waveformSvg.get(0).appendChild(newpath);

            waveformBars.push(newpath);
        }
    };

    const init = () => {
        drawWaveform();
        scrubber.on('click', onLoadAudio);
        button.on('click', onLoadAudio);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        loaded = false;
        playing = false;

        if (audio) {
            audio.pause();
            audio.removeEventListener('canplay', onCanPlay);
            audio.removeEventListener('timeupdate', onTimeUpdate);
            audio.removeEventListener('play', onStateChanged);
            audio.removeEventListener('playing', onStateChanged);
            audio.removeEventListener('pause', onStateChanged);
            audio.removeEventListener('ended', onStateChanged);
            audio.removeEventListener('seeked', onSeeked);
        }

        scrubber.off('click');
        scrubber.off('mousemove');
        scrubber.off('mouseenter');
        scrubber.off('mouseleave');
        button.off('click');
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
