import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Components from '../core/Components';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { loadSuperagent, loadGsap } from '../lib/async-bundles';

import { DOM_UPDATED, START_LOADER, FINISH_LOADER } from '../lib/events';
import Viewport from '../core/Viewport';

export default (el, props) => {
    const $el = $(el);
    const $form = $el.find('form');
    const $input = $el.find('#searchInput');
    const $selectedTermsWrapper = $el.find('[data-selected-terms-wrapper]');
    const $selectedTermsList = $el.find('[data-selected-terms-list]');
    const $termsList = $el.find('[data-terms-list]');
    const $templateListItem = $el.find('[data-term-template]');
    const $resetButtonWrapper = $el.find('[data-reset-button-wrapper]');
    const $resultWrapper = $el.find('[data-search-result-wrapper]');
    const $searchButton = $el.find('[data-search-submit-button]');
    const $resultSummary = $el.find('[data-search-result-summary]');

    const { availableTerms, highlightedSearchTerms, searchUrl } = props;
    let { selectedTerms, currentFilter } = props;

    let updateTimeout = null;
    let searchTimeout = null;
    let hasUpdatedHistory = false;
    let searchButtonVisible = false;
    let viewMode = 'list';

    let isLoading = false;
    let superagent = null;
    let gsap = null;

    const init = () => {
        loadSuperagent(loadedSuperagent => {
            superagent = loadedSuperagent;
        });

        if (UserPreferencesHandler.shouldAnimate()) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });
            });
        }

        $el.on('click', '[data-selected-term]', e => {
            e.preventDefault();
            removeTerm($(e.triggerTarget).data('selected-term'));
        });

        $el.on('click', '[data-term]', e => {
            e.preventDefault();
            addTerm($(e.triggerTarget).data('term'));
        });

        $el.on('click', '[data-reset-button]', e => {
            e.preventDefault();
            reset();
        });

        $el.on('click', '[data-filter-on]', e => {
            e.preventDefault();
            changeFilter($(e.triggerTarget).data('filter-on'));
        });
        
        $el.on('change', 'input[type="radio"]', e => {
            e.preventDefault();
            changeViewMode($(e.triggerTarget).val());
        });        

        $form.on('submit', onFormSubmit);
        $input.on('change keyup', onInputChange);

        window.history.replaceState({ reloadBack: true }, document.title);
    };

    const destroy = () => {
        $el.off('click');
        $form.off('submit');
        $input.off('change keyup');
    };

    const onFormSubmit = e => {
        e.preventDefault();

        if ($input.val().length > 1) {
            addTerm($input.val());
            $input.val('');
            $input.focus();
        }
    };

    const onInputChange = e => {
        clearTimeout(updateTimeout);

        updateTimeout = setTimeout(() => {
            updateSuggestedTermsList();
        }, e.type === 'change' ? 500 : 0);

        checkSubmitVisibility();
    };

    const checkSubmitVisibility = () => {
        if (searchButtonVisible === false && $input.val().length > 1) {
            $searchButton.css({ display: 'block', opacity: 1 });

            if (gsap) {
                gsap.from($searchButton.get(0), { duration: 0.2, opacity: 0 });
            }

            searchButtonVisible = true;
        } else if (searchButtonVisible === true && $input.val().length <= 1) {
            if (gsap) {
                gsap.to($searchButton.get(0), {
                    duration: 0.2, opacity: 0, onComplete: () => {
                        $searchButton.css({ display: 'none' });
                    }
                });
            } else {
                $searchButton.css({ display: 'none' });
            }
            searchButtonVisible = false;
        }
    };

    const removeTerm = term => {
        const index = selectedTerms.indexOf(term);

        if (index !== -1) {
            selectedTerms.splice(index, 1);
            updateSearch();
        }
    };

    const addTerm = term => {
        const index = selectedTerms.indexOf(term);

        if (index === -1) {
            selectedTerms.push(term);
            updateSearch();
        }
    };

    const reset = () => {
        selectedTerms = [];
        updateSearch();
    };

    const changeFilter = filter => {
        currentFilter = filter;
        updateSearch();
    };

    const changeViewMode = newViewMode => {
        viewMode = newViewMode;
        updateSearch();
    };

    const updateSearch = () => {
        clearTimeout(updateTimeout);
        //$input.val('');

        $selectedTermsList.find('[data-term-list-item]').remove();

        if (selectedTerms.length > 0) {
            $selectedTermsWrapper.css({ display: 'block' });
            selectedTerms.forEach(term => {
                const $newTerm = $templateListItem.clone();
                $newTerm.find('button').text(term).attr({ 'data-selected-term': term });
                $selectedTermsList.append($newTerm);
            });

            $selectedTermsList.append($resetButtonWrapper);

        } else {
            $selectedTermsWrapper.css({ display: 'none' });
        }

        updateSuggestedTermsList();
        updateHistory();

        clearTimeout(searchTimeout);
        
        $resultWrapper.css({ opacity: 0.3, 'pointer-events': 'none' });
        
        Dispatch.emit(START_LOADER);
        
        searchTimeout = setTimeout(() => {
            doSearch();
        }, 300);
    };

    const updateSuggestedTermsList = () => {
        $termsList.empty();

        const currentVal = $input.val();
        const isHighlighted = $input.val() === '';
        
        if (isHighlighted && selectedTerms.length > 0 && Viewport.breakpoint.size < 750) {
            return;
        }
        
        const terms = isHighlighted ? highlightedSearchTerms : availableTerms;
        let c = 0;

        terms.forEach(term => {
            if (isHighlighted || term.toLowerCase().startsWith(currentVal.toLowerCase())) {
                if (Viewport.breakpoint.size < 750 && c++ >= 6) {
                    return;
                }

                const $newTerm = $templateListItem.clone();

                $newTerm.find('button').text(term).attr({ 'data-term': term });

                if (selectedTerms.includes(term)) {
                    $newTerm.addClass('opacity-50').attr({ disabled: '' });
                }


                $termsList.append($newTerm);
            }
        });
    };

    const updateHistory = () => {
        if (window.history) {
            const newUrl = searchUrl + (selectedTerms.length > 0 ? (searchUrl.indexOf('?') !== -1 ? '&' : '?') + 'terms=' + (selectedTerms.join('|')) + (currentFilter !== 'all' ? ('&filter=' + currentFilter) : '') + ('&viewMode=' + viewMode) : '');

            if (hasUpdatedHistory) {
                window.history.replaceState({ reloadBack: true }, document.title, newUrl);
            } else {
                window.history.pushState({ reloadBack: true }, document.title, newUrl);
            }

            hasUpdatedHistory = true;
        }
    };

    const doSearch = () => {
        isLoading = true;

        if (superagent === null) {
            console.error('Superagent bundle not loaded');
            return;
        }
        
        superagent
            .get(searchUrl)
            .query({ ajax: 'yes' })
            .set({ 'X-Requested-With': 'XMLHttpRequest' })
            .query(('terms=' + selectedTerms.join('|')) + (currentFilter !== 'all' ? '&filter=' + currentFilter : '') + ('&viewMode=' + viewMode))
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }

                insertData(text, false);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
                $resultWrapper.css({ opacity: '', 'pointer-events': '' });
            });
    };

    const insertData = (html, append) => {
        const $newHtml = $(`<div>${html}</div>`);
        $newHtml.find('noscript').remove();

        const $newResultsNav = $newHtml.find('[data-search-result-nav]');
        const $newResults = $newHtml.find('[data-search-result-results]');
        
        $resultWrapper.empty();
        $resultWrapper.append($newResultsNav);
        $resultWrapper.append($newResults);
        
        $resultSummary.text('Showing ' + $newResults.find('li').length + ' new search results.');

        Dispatch.emit(FINISH_LOADER);
        Dispatch.emit(DOM_UPDATED);

        Components.init();
    };

    return {
        init,
        destroy
    };
};
