import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { loadSuperagent } from "../lib/async-bundles";
import { loadGsap } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    const $body = $('body');
    const clocks = [];

    const { timeEndpoint } = props;

    let serverStartTimes = [];
    let clientStartTime = null;

    let interval = null;
    let gsap = null;

    const init = () => {
        $el.find('[data-office-clock]').each(item => {
            const $item = $(item);

            const newClock = {
                $item,
                $hour: $item.find('[data-office-clock-hour]'),
                $minute: $item.find('[data-office-clock-minute]'),
                $second: $item.find('[data-office-clock-second]'),
                $text: $item.find('[data-office-clock-text]'),
                timezone: $item.data('office-clock')
            };

            if (newClock.$hour.length > 0 && newClock.$minute.length > 0 && newClock.$second.length > 0 && !Number.isNaN(newClock.offset)) {
                clocks.push(newClock);
            }
        });

        if (clocks.length > 0) {
            loadSuperagent(superagent => {
                superagent
                    .get(timeEndpoint)
                    .set({ 'X-Requested-With': 'XMLHttpRequest' })
                    .type('application/json')
                    .then(({ status, body }) => {
                        if (status !== 200 || !body) {
                            throw new Error();
                        }
                        initClocks(body);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            });

            $el.on('mouseenter', onMouseEnter);
            $el.on('mouseleave', onMouseLeave);

            $el.find('[data-office-clock]').on('mouseenter', onClockMouseEnter);
            $el.find('[data-office-clock]').on('mouseleave', onClockMouseLeave);
        }

        if (UserPreferencesHandler.shouldAnimate() && !$('html').hasClass('touch')) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });
            });
        }
    };

    const destroy = () => {
        clearInterval(interval);

        $el.off('mouseenter', onMouseEnter);
        $el.off('mouseleave', onMouseLeave);
    };

    const initClocks = serverTimes => {
        serverStartTimes = serverTimes;
        clientStartTime = (new Date()).getTime();

        updateOfficeTime();

        interval = setInterval(() => {
            updateOfficeTime();
        }, 1000);

        $el.find('[data-office-clock]').removeClass('opacity-0');
    };

    const updateOfficeTime = () => {
        clocks.forEach(clock => {
            const serverTimeForTimezone = serverStartTimes[clock.timezone];

            if (serverTimeForTimezone) {
                const officeTime = new Date(null, null, null, serverTimeForTimezone['h'], serverTimeForTimezone['m'], serverTimeForTimezone['s']);
                officeTime.setTime(officeTime.getTime() + (new Date()).getTime() - clientStartTime);

                const hr = officeTime.getHours();
                const min = officeTime.getMinutes();
                const sec = officeTime.getSeconds();

                const sec_rotation = (6 * sec) - 180;
                const min_rotation = (6 * min) - 180;
                const hr_rotation = (360 / 12 * hr) - 180; //converting current time

                clock.$hour.get(0).style.transform = `rotate(${hr_rotation}deg)`;
                clock.$minute.get(0).style.transform = `rotate(${min_rotation}deg)`;
                clock.$second.get(0).style.transform = `rotate(${sec_rotation}deg)`;

                clock.$text.text(String(hr).padStart(2, '0') + ':' + String(min).padStart(2, '0') + ':' + String(sec).padStart(2, '0'));
            }
        });
    };

    const onClockMouseEnter = e => {
        const $text = $(e.triggerTarget).find('[data-office-clock-text]');
        
        if (gsap) {
            gsap.to($text.get(0), { opacity: 1, duration: 0.1 })
        } else {
            $text.css({ opacity: 1 });
        }
    };

    const onClockMouseLeave = e => {
        const $text = $(e.triggerTarget).find('[data-office-clock-text]');
        
        if (gsap) {
            gsap.to($text.get(0), { opacity: 0, duration: 0.1 })
        } else {
            $text.css({ opacity: 0 });
        }
    };

    const onMouseEnter = e => {
        $body.on('mousemove', onMouseMove);
    };

    const onMouseLeave = e => {
        $body.off('mousemove', onMouseMove);
    };

    const onMouseMove = e => {
        clocks.forEach(clock => {
            const position = getPosition(e, clock.$item);
            if (gsap) {
                gsap.to(clock.$text.get(0), { duration: 0.4, ease: 'quint.out', x: position.x, y: position.y });
            } else {
                clock.$text.css({ transform: `translateX(${position.x}px) translateY(${position.y}px)` })
            }
        });
    };

    const getPosition = (e, $clock) => {
        return {
            x: e.pageX - $clock.offset().left + 12,
            y: e.pageY - $clock.offset().top
        }
    };

    return {
        init,
        destroy
    };
};
