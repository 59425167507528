import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';
import { HOVER_LIST_UPDATED } from '../lib/events';

import { loadGsap } from '../lib/async-bundles';

export default (el, props) => {
    const $el = $(el);
    const $body = $('body');

    let gsap = null;

    let hoverDefs = [];
    let $hoverContainer = null;
    let hoverContainerVisible = false;
    let hoverContainerToX = null;
    let hoverContainerToY = null;
    let hasInitedImages = false;
    let isFirstMove = true;
    let currentlyActive = null;
    let hideTimeout = null;
    let unmoveTimeout = null;
    
    let isScrolling = false;
    let didEnterDuringScroll = false;
    let scrollTimeout = null;

    const init = () => {
        if (UserPreferencesHandler.shouldAnimate() && !$('html').hasClass('touch')) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                //gsap.defaults({ overwrite: 'auto' });

                Viewport.on('scroll', onScroll);
                Dispatch.on(HOVER_LIST_UPDATED, onMaybeUpdated);
                $el.on('mouseenter', onEnterElement).on('mouseleave', onLeaveElement);
            });
        }
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Dispatch.off(HOVER_LIST_UPDATED, onMaybeUpdated);
        $el.off('mouseenter', onEnterElement).off('mouseleave', onLeaveElement);
        
        $el.find('[data-hover-link]').each(item => {
            const $link = $(item);
            $link.off('mouseenter', onMouseEnter).off('mouseleave', onMouseLeave);
        });
    };
    
    const onScroll = e => {
        isScrolling = true;
        
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
            isScrolling = false;
            
            if (didEnterDuringScroll) {
                onEnterElement();
            }
        }, 200);
    };

    const onMaybeUpdated = () => {
        initHoverImages();
    };
    
    const onEnterElement = () => {
        if (!isScrolling) {
            if (!hasInitedImages) {
                initHoverContainer();
                initHoverImages();
                hasInitedImages = true;
            }

            clearTimeout(unmoveTimeout);
            isFirstMove = true;
            $body.off('mousemove', onBodyMouseMove);
            $body.on('mousemove', onBodyMouseMove);
        } else {
            didEnterDuringScroll = true;
        }
    };

    const onLeaveElement = () => {
        clearTimeout(unmoveTimeout);
        didEnterDuringScroll = false;

        unmoveTimeout = setTimeout(() => {
            $body.off('mousemove', onBodyMouseMove);
        }, 250);
    };

    const initHoverImages = () => {
        const prefs = UserPreferencesHandler.getPrefs();

        $el.find('[data-hover-link]').each(item => {
            const $link = $(item);
            
            if ($link.data('hover-link-processed') !== 'true') {
                const linkId = $link.data('hover-link');
                const imageSrcsets = $link.data('hover-images');
    
                let images = [];
    
                imageSrcsets.forEach(data => {
                    const $imageElement = $('<img>').attr('src', prefs.lowres !== 'on' ? data.full : data.low).addClass('absolute object-contain object-left w-100 h-100 hidden pointer-events-none');
                    
                    if (prefs.lowres === 'on') {
                        $imageElement.css({ 'image-rendering': 'pixelated' });
                    }
    
                    if ($hoverContainer && $hoverContainer.length) {
                        $hoverContainer.append($imageElement);
                    }
    
                    images.push({
                        highResSrc: data.full,
                        lowResSrc: data.low,
                        $hoverImage: $imageElement
                    });
                });
    
                hoverDefs[linkId] = {
                    $element: $link,
                    images: images
                };
    
                $link.on('mouseenter', onMouseEnter).on('mouseleave', onMouseLeave);
                
                $link.data('hover-link-processed', 'true')
            }
        });
    };

    const initHoverContainer = () => {
        $hoverContainer = $('<div>').addClass('absolute w-[300px] h-[300px] left-0 top-0 z-10 pointer-events-none').css({ opacity: 0 });
        $el.append($hoverContainer);
        
        hoverContainerToX = gsap.quickTo($hoverContainer.get(0), "x", { duration: 0.8, ease: 'quint.out' });
        hoverContainerToY = gsap.quickTo($hoverContainer.get(0), "y", { duration: 0.8, ease: 'quint.out' });

    };

    const onMouseEnter = e => {
        clearTimeout(hideTimeout);

        if (!isScrolling) {
            const $link = $(e.currentTarget);
            const linkId = $link.data('hover-link');
            const def = hoverDefs[linkId];
    
            if (def !== undefined) {
                currentlyActive = linkId;
                $link.on('mousemove', onMouseMove);
            }
        }
    };

    const onMouseLeave = e => {
        const $links = $el.find('[data-hover-link]');
        $links.off('mousemove');
        
        clearTimeout(hideTimeout);
        hideTimeout = setTimeout(() => {
            gsap.to($hoverContainer.get(0), { duration: 0.1, opacity: 0, ease: 'sine.inOut' });
            hoverContainerVisible = false;
        }, 50);
    };

    const onMouseMove = e => {
        if (currentlyActive !== null) {
            const def = hoverDefs[currentlyActive];
            const images = def.images;

            $hoverContainer.find('img').addClass('hidden');

            if (images.length === 0) {
                return;
            }
            
            const useImage = Math.min(Math.max(Math.floor(e.layerX / ((def.$element.get(0).getBoundingClientRect().width - 500) / images.length)), 0), images.length - 1);
            
            def.images[useImage].$hoverImage.removeClass('hidden');

            if (!hoverContainerVisible) {
                gsap.to($hoverContainer.get(0), { duration: 0.1, opacity: 1, ease: 'sine.inOut' });
                hoverContainerVisible = true;
            }
        }
    };

    const onBodyMouseMove = e => {
        if (gsap) {
            const position = getPosition(e);
            const xPos = position.x + 400;
            const yPos = position.y - 150;
            
            hoverContainerToX(xPos, isFirstMove ? xPos : null);
            hoverContainerToY(yPos, isFirstMove ? yPos : null);

            isFirstMove = false;
        }
    };

    const getPosition = e => {
        return {
            x: e.pageX - $el.offset().left,
            y: e.pageY - $el.offset().top
        }
    };

    return {
        init,
        destroy
    };
};
