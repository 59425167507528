import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';
import { START_LOADER, FINISH_LOADER } from '../lib/events';

import { loadGsap } from '../lib/async-bundles';

export default (el, props) => {
    let gsap;
    let progressTl;
    let endTl;
    let loadEndTimeout;

    const init = () => {
        if (UserPreferencesHandler.shouldAnimate()) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });

                gsap.set(el, { display: 'block', scaleX: 0 });
                initLoader();
            });
        }

    };

    const destroy = () => {
        if (gsap) {
            Dispatch.off(START_LOADER, onStartLoader);
            Dispatch.off(FINISH_LOADER, onFinishLoader);
        }
    };

    const initLoader = () => {
        progressTl = gsap.timeline({ paused: true })
            .fromTo(el, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            }, 0)
            .fromTo(el, { scaleX: 0 }, {
                scaleX: 1,
                duration: 2,
                transformOrigin: 'left center',
                ease: 'Cubic.easeOut'
            }, 0);

        Dispatch.on(START_LOADER, onStartLoader);
        Dispatch.on(FINISH_LOADER, onFinishLoader);

    };

    const onStartLoader = () => {
        if (gsap) {
            if (endTl) {
                endTl.kill();
                endTl = null;
            }
            if (progressTl.time()) {
                gsap.to(progressTl, {
                    progress: 0,
                    duration: 0.15,
                    ease: 'none',
                    onComplete() {
                        progressTl.invalidate();
                        progressTl.play(0);
                    }
                });
            } else {
                progressTl.invalidate();
                progressTl.play();
            }
        }
    };

    const onFinishLoader = () => {
        if (gsap) {
            if (endTl) {
                endTl.kill();
            }
            gsap.killTweensOf(progressTl);
            if (loadEndTimeout) {
                clearTimeout(loadEndTimeout);
            }
            loadEndTimeout = setTimeout(() => {
                clearTimeout(loadEndTimeout);
                loadEndTimeout = null;
                endTl = gsap.timeline({
                    onComplete() {
                        endTl = null;
                        gsap.set(el, { clearProps: 'opacity' });
                        progressTl.invalidate();
                        progressTl.pause(0);
                    }
                });
                if (progressTl.time()) {
                    endTl.to(progressTl, {
                        progress: 1,
                        duration: progressTl.progress() <= 0.9 ? 0.3 : 0.1,
                        ease: 'none'
                    }, 0);
                }
                endTl.to(el, {
                    opacity: 0,
                    duration: 0.3
                }, '+=0.1');
            }, 60);
        }
    };

    return {
        init,
        destroy
    };
};
