const wrap = (n, m) => ((n % m) + m) % m;

export class SlideshowAnimator {
    constructor(
        slideshow,
        gsap,
        { startTimeout = 2000, settleTimeout = 5000, speed = 25 } = {}
    ) {
        this.slideshow = slideshow;
        this.gsap = gsap;
        this.speed = speed;
        this.hasStarted = false;

        this.animation = {
            velocity: 0,
            lastFrameTime: null,
            intervalID: null,
            isScrolling: false,
        };

        setTimeout((_) => {
            this.hasStarted = true;
            this.start();
        }, startTimeout);

        this.scrollTimeoutID = undefined;
        this.slideshow
            .on("dragStart", this.pause.bind(this))
            .on("settle", (e, p) => {
                if (this.animation.isScrolling) {
                    return;
                }

                if (this.hasStarted) {
                    this.scrollTimeoutID = setTimeout(
                        this.start.bind(this),
                        settleTimeout
                    );
                }
            });
    }

    translateSlideshow(delta) {
        this.slideshow.x = this.slideshow.x - delta;
        this.slideshow.settle(this.slideshow.x);

        let prevIndex = this.slideshow.selectedIndex;
        this.slideshow.selectedIndex = this.slideshow.dragEndRestingSelect();

        if (prevIndex != this.slideshow.selectedIndex) {
            this.slideshow.dispatchEvent("change", null, [
                this.slideshow.selectedIndex,
            ]);
        }

        this.slideshow.updateSelectedSlide();
    }

    scroll() {
        let now = new Date();
        let deltaTime =
            (this.animation.lastFrameTime ? now - this.animation.lastFrameTime : 16) /
            1000;

        this.translateSlideshow(this.speed * this.animation.velocity * deltaTime);

        this.animation.lastFrameTime = now;
    }

    start() {
        this.animation.lastFrameTime = new Date();
        this.animation.isScrolling = true;
        this.gsap.to(this.animation, {
            velocity: 1.0,
            duration: 3.0,
            ease: "sine.inOut",
            overwrite: true,
        });

        clearInterval(this.animation.intervalID);
        this.animation.intervalID = setInterval(() => this.scroll(), 16);
    }

    pause() {
        if (this.animation.intervalID) {
            this.animation.isScrolling = false;
            // window.cancelAnimationFrame(animatorID);
            clearInterval(this.animation.intervalID);
            this.animation.intervalID = undefined;
            this.animation.velocity = 0;
        }
        clearTimeout(this.scrollTimeoutID);
    }
}
