import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import GalleryViewer from './lib/GalleryViewer';
import UserPreferencesHandler from './lib/UserPreferencesHandler';
import PopupHandler from './lib/PopupHandler';
import PageTransition from './lib/PageTransition';
import Animator from './lib/Animator';
import ViewTracker from './lib/ViewTracker';

const init = () => {
    Viewport.init();
    UserPreferencesHandler.init();
    ViewTracker.init();
    Animator.init();
    PopupHandler.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    PageTransition.init();
    GalleryViewer.init();
    
    document.addEventListener('lazybeforesizes', function(e){
        if (UserPreferencesHandler.getPrefs().lowres === 'on') {
            e.detail.width = (e.detail.width / 2) / window.devicePixelRatio;
        }
    });
        
    lazySizes.init();

    window.addEventListener('popstate', e => {
        const { state } = e;

        if (state && state.reloadBack) {
            window.location.reload();
        }
    });
    
    $('body').on('click', '[data-view-gallery-btn]', e => {
        const $images = $('[data-gallery-view-target] [data-gallery-image]');
        if ($images.length > 0) {
            GalleryViewer.show($images, $(e.triggerTarget).data('view-gallery-btn'));
        }
    });
    
    
};

require('doc-ready')(init);
