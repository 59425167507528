import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { loadGsap } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    const $body = $('body');

    const $navInner = $body.find('[data-nav-inner]');
    const $contentBlocks = $body.find('[data-content-blocks]');
    const $chapters = $body.find('[data-chapter-block]');
    const $chapterProgress = $body.find('[data-chapter-progress]');
    const $chapterProgressWrap = $body.find('[data-chapter-progress-wrap]');

    const $chapterList = $el.find('[data-chapter-list]');
    const $listWrapper = $el.find('[data-list-wrapper]');

    const $toggleButton = $el.find('[data-toggle-button]');
    const $toggleButtonSvg = $el.find('[data-toggle-button] svg');

    let isOpened = false;
    let isVisible = false;
    let openedScrollPosition = 0;
    let chaptersDef = [];
    let currentActiveChapter = -1;
    let currentEndOffsetY = 0;
    let currentStartOffsetY = 0;
    let gsap = null;
    let progressTo = null;

    const init = () => {
        $el.on('click', e => {
            toggleList();
        });

        $toggleButton.on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            toggleList();
        });

        $chapterList.on('click', 'a', e => {
            e.preventDefault();
            e.stopPropagation();

            if (isOpened) {
                gotoChapter($(e.triggerTarget));
            } else {
                toggleList();
            }
        });

        updateOffsets();
        buildChaptersDef();
        checkActiveChapter();
        checkVisibility();

        if (UserPreferencesHandler.shouldAnimate()) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });
                gsap.registerPlugin(ScrollToPlugin);
                progressTo = gsap.quickTo($chapterProgress.get(0), "width", { duration: 0.4, ease: 'quint.out' });
            });
        }

        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        document.addEventListener('keyup', onKeyUp);
    };

    const destroy = () => {
        $toggleButton.off('click');
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        document.removeEventListener('keyup', onKeyUp);
    };

    const onKeyUp = e => {
        const key = e.keyCode || e.which;

        if (isOpened && key === 27) {
            toggleList();
        }
    };

    const onScroll = e => {
        checkActiveChapter();
        checkVisibility();
        updateProgress();

        if (isOpened && (Math.abs(openedScrollPosition - Viewport.scrollTop) > 200)) {
            toggleList()
        }
    };

    const onResize = e => {
        updateOffsets();
        buildChaptersDef();
        checkActiveChapter();
        checkVisibility();
        updateProgress();
    };

    const buildChaptersDef = () => {
        chaptersDef = [];

        $chapters.each(chapter => {
            let $chapter = $(chapter);
            chaptersDef.push({
                offset: $chapter.offset().top,
                name: $chapter.data('chapter-block'),
                num: $chapter.data('chapter-num'),
            });
        });
    };

    const updateOffsets = () => {
        currentEndOffsetY = $contentBlocks.offset().top + $contentBlocks.height();
        currentStartOffsetY = $contentBlocks.offset().top;
    };

    const checkActiveChapter = () => {
        const scrollOffset = Viewport.scrollTop;
        let activeChapter = 0;

        chaptersDef.forEach((item, i) => {
            if (item.offset < scrollOffset + (Viewport.height / 2)) {
                activeChapter = i;
            }
        });

        if (currentActiveChapter !== activeChapter) {
            setActiveChapter(activeChapter);
        }
    };

    const setActiveChapter = activeChapter => {
        currentActiveChapter = activeChapter;
        $chapterList.find('[data-chapter-item]').removeClass('text-foreground is-selected');
        $chapterList.find('[data-chapter-item="' + chaptersDef[activeChapter].name + '"]').addClass('text-foreground is-selected');

        positionChapterList();
    };

    const positionChapterList = () => {
        if (!isOpened) {
            const $chapter = $chapterList.find('[data-chapter-item="' + chaptersDef[currentActiveChapter].name + '"]')
            
            if ($chapter.length > 0) {
                const newPos = -$chapter.position().top;
    
                if (UserPreferencesHandler.shouldAnimate() && gsap) {
                    gsap.to($chapterList.get(0), { duration: 0.2, ease: 'sine.out', top: newPos });
                } else {
                    $chapterList.css('top', newPos);
                }
    
                openedScrollPosition = Viewport.scrollTop;
            }
        } else {
            if (UserPreferencesHandler.shouldAnimate() && gsap) {
                gsap.to($chapterList.get(0), { duration: 0.2, ease: 'sine.out', top: 0 });
            } else {
                $chapterList.css('top', 0);
            }
        }
    };

    const toggleList = () => {
        if (isOpened) {
            $listWrapper.removeClass('is-opened');

            if (UserPreferencesHandler.shouldAnimate() && gsap) {
                $listWrapper.css({ height: '' })
                const targetHeight = $listWrapper.height();
                $listWrapper.css({ height: 'auto' })
                
                gsap.to($listWrapper.get(0), {
                    duration: 0.2, ease: 'sine.out', height: targetHeight, onComplete: () => {
                        $listWrapper.css({ height: '' })
                    }
                });
            } else {
                $listWrapper.css('height', '');
            }

            $toggleButtonSvg.addClass('rotate-180');
        } else {
            $listWrapper.addClass('is-opened');

            if (UserPreferencesHandler.shouldAnimate() && gsap) {
                gsap.to($listWrapper.get(0), { duration: 0.2, ease: 'sine.out', height: $chapterList.height() });
            } else {
                $listWrapper.css('height', $chapterList.height());
            }

            $toggleButtonSvg.removeClass('rotate-180');
        }

        isOpened = !isOpened;

        positionChapterList();
    };

    const checkVisibility = () => {
        if (isVisible && (Viewport.scrollTop + Viewport.height > currentEndOffsetY || Viewport.scrollTop + Viewport.height < currentStartOffsetY)) {
            hide();
        } else if (!isVisible && (Viewport.scrollTop + Viewport.height > currentStartOffsetY) && Viewport.scrollTop + Viewport.height < currentEndOffsetY) {
            show();
        }
    };

    const updateProgress = () => {
        const progress = Math.max(0, Math.min((Viewport.scrollTop) / (currentEndOffsetY - Viewport.height), 1));

        if (UserPreferencesHandler.shouldAnimate() && gsap) {
            progressTo(progress * $chapterProgressWrap.width());
        } else {
            $chapterProgress.css('width', Math.round(progress * 100) + '%');
        }
    };

    const show = () => {
        $navInner.removeClass('translate-y-[calc(100%+24px)]').addClass('translate-y-0%');
        isVisible = true;
    };

    const hide = () => {
        $navInner.addClass('translate-y-[calc(100%+24px)]').removeClass('translate-y-0%');
        isVisible = false;
    };

    const gotoChapter = $trigger => {
        const $target = $chapters.filter($trigger.attr('href'));

        if ($target.length > 0) {
            const newPos = $target.offset().top - parseInt($target.css('scroll-margin-top'));

            if (UserPreferencesHandler.shouldAnimate() && gsap) {
                gsap.to(window, { duration: 1, ease: 'power2.inOut', scrollTo: newPos });
            } else {
                window.scrollTo(0, newPos);
            }

            if (isOpened) {
                toggleList();
            }
        }
    };

    return {
        init,
        destroy
    };
};
