import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { FLICKITY_DRAG_MOVE } from "../lib/events";

import { loadGsap } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    const $body = $('body');
    const $followCircle = $el.find('[data-follow-circle]');

    const { contentType } = props;

    let gsap = null;
    let isFirstMove = true;
    let isVisible = false;

    let isScrolling = false;
    let didEnterDuringScroll = false;
    let scrollTimeout = null;
    
    let lastMoveEvent = null;

    const init = () => {
        if (UserPreferencesHandler.shouldAnimate() && !$('html').hasClass('touch')) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });
            });

            $el.css({ cursor: 'none' }).find('*').css({ cursor: 'none' });
            $el.on('mouseenter', onMouseEnter);
            $el.on('mouseleave', onMouseLeave);

            if (contentType === 'imagegallery') {
                Dispatch.on(FLICKITY_DRAG_MOVE, onFlickityDragMove);
            }

            $followCircle.removeClass('center').css({ opacity: 0 });

            Viewport.on('scroll', onScroll);
        }

        if (UserPreferencesHandler.getPrefs().simplified === 'on' && contentType === 'videoembed') {
            $followCircle.css({ opacity: 1 });
        }
    };

    const destroy = () => {
        $el.off('mouseenter');
        $el.off('mouseleave');
        $body.off('mousemove');

        if (contentType === 'imagegallery') {
            Dispatch.off(FLICKITY_DRAG_MOVE, onFlickityDragMove);
        }
    };

    const onScroll = e => {
        isScrolling = true;

        clearTimeout(scrollTimeout);
        
        scrollTimeout = setTimeout(() => {
            isScrolling = false;
            
            if (didEnterDuringScroll) {
                onMouseEnter(e);
            }
        }, 200);
    };

    const onMouseEnter = e => {
        if (!isScrolling) {
            if (gsap) {
                const position = getPosition(e);
                gsap.set($followCircle.get(0), { scale: 0, opacity: 1, x: position.x, y: position.y });
            }
            isFirstMove = true;
            isVisible = true;
            $body.on('mousemove', onMouseMove);
        } else {
            didEnterDuringScroll = true;
        }
    };

    const onMouseLeave = e => {
        if (gsap) {
            gsap.to($followCircle.get(0), {
                duration: 0.2, scale: 0, ease: 'sine.inOut', onComplete: () => {
                    $body.off('mousemove', onMouseMove);
                }
            });
            isVisible = false;
            lastMoveEvent = null;
        }
        didEnterDuringScroll = false;
    };

    const onMouseMove = e => {
        if (gsap) {
            const position = getPosition(e);
            gsap.to($followCircle.get(0), { duration: 0.5, ease: 'quint.out', x: position.x, y: position.y });

            if (isFirstMove) {
                gsap.to($followCircle.get(0), { duration: 0.4, scale: 1, ease: 'sine.out' });
                isFirstMove = false;
            }
            
            lastMoveEvent = e;
        }
    };

    const onFlickityDragMove = (key, e) => {
        onMouseMove(e.data);
    };

    const getPosition = e => {
        return {
            x: e.pageX - $el.offset().left,
            y: e.pageY - $el.offset().top
        }
    };

    return {
        init,
        destroy
    };
};
