import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { loadSuperagent } from "../lib/async-bundles";
import { FILTER_UPDATED, FILTER_FORM_UPDATED, DOM_UPDATED } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $paginationWrapper = $el.find('[data-pagination-wrapper]');

    let isLoading = false;
    let superagent = null;

    const init = () => {
        loadSuperagent(loadedSuperagent => {
            superagent = loadedSuperagent;

            $paginationWrapper.on('click', 'a', e => {
                e.preventDefault();
                loadNextPage();
            });
        });
    };

    const destroy = () => {
        $paginationWrapper.off('click');
    };

    const loadNextPage = () => {
        if (isLoading) {
            return;
        }

        isLoading = true;

        const url = $paginationWrapper.find('a').attr('href');

        if (superagent === null) {
            console.error('Superagent bundle not loaded');
            return;
        }

        superagent
            .get(url)
            .query({ ajax: 'yes' })
            .set({ 'X-Requested-With': 'XMLHttpRequest' })
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                insertData(text);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
            });
    };

    const insertData = (html,) => {
        const $newHtml = $(`<div>${html}</div>`);
        $newHtml.find('noscript').remove();

        const $newResultsList = $newHtml.find('[data-news-list]');
        const $newPaginationWrapper = $newHtml.find('[data-pagination-wrapper]');

        if ($newResultsList.length) {
            const $resultsList = $el.find('[data-news-list]');
            $resultsList.attr('class', $newResultsList.attr('class'));
            $resultsList.append($newResultsList.get(0).children);
        } else {

        }

        if ($newPaginationWrapper.length > 0) {
            const $resultsList = $el.find('[data-pagination-wrapper]');
            $resultsList.empty().append($newPaginationWrapper.get(0).children);
        }

        Dispatch.emit(DOM_UPDATED);
    };

    return {
        init,
        destroy
    };

};
