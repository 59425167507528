import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { loadModelViewer } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    const $modelWrapper = $el.find('[data-model-wrapper]');
    
    const { file } = props;
    
    const init = () => {
        const prefs = UserPreferencesHandler.getPrefs()
        
        if (prefs.simplified !== 'on') {
            loadModelViewer(loaded => {
                const ModelViewer = loaded.ModelViewer;
                const viewer = new ModelViewer($modelWrapper.get(0), file);
            });
        } else {
            $el.find('[data-fallback-image]').removeClass('hidden');
            $modelWrapper.addClass('hidden');
        }
    };

    const destroy = () => {
        
    };
    
    return {
        init,
        destroy
    };
};
