import $ from '../core/Dom';

export default el => {

    const clickHandler = e => {
        $(el).toggleClass('show-raster');
    };

    const init = () => {
        $(el).find('#toggleBtn').on('click', clickHandler);

        window.document.addEventListener("keydown", (event) => {
            if (event.keyCode === 82) {
                clickHandler();
            }
        });
    };

    const destroy = () => {
    };

    return {
        init,
        destroy
    };

};
