import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { loadCanvas } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    const $modelWrapper = $el.find('[data-model-wrapper]');
    
    const init = () => {
        const prefs = UserPreferencesHandler.getPrefs()
        
        if (prefs.simplified !== 'on') {
            loadCanvas(loaded => {
                const Canvas = loaded.Snowcanvas;
                const canvas = new Canvas($el.get(0));
            });
        } else {
            // TODO : What to do?
            /*
            $el.find('[data-fallback-image]').removeClass('hidden');
            $modelWrapper.addClass('hidden');
             */
        }
    };

    const destroy = () => {
        
    };
    
    return {
        init,
        destroy
    };
};
