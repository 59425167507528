import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';
import Viewport from '../core/Viewport';

const loadLottie = require('bundle-loader?lazy&name=[name]!lottie-web');

export default (el, props) => {
    const $el = $(el);
    const $logoWrap = $el.find('[data-logo-wrap]');
    const $logoEmoticon = $el.find('[data-logo-emoticon]');

    let logoAnimation = null;
    let lottiePlayTimer = null;
    let hasMouseInteracted = false;
    let observer;

    const init = () => {
        if (!UserPreferencesHandler.shouldAnimate()) {
            return;
        }
        
        $logoEmoticon.css({ display: 'none' });
        
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            
            bootLottie();
            
            observer.disconnect();
            observer = null;
        });
        
        observer.observe(el);
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
    };

    const bootLottie = () => {
        loadLottie(lottie => {
            logoAnimation = lottie.loadAnimation({
                container: $logoWrap.get(0),
                renderer: 'svg',
                loop: false,
                autoplay: false,
                path: '/assets/static/logo-animation-20230525.json'
            });

            $('body').on('mousemove', onMouseMove);
            
            lottiePlayTimer = setTimeout(() => {
                logoAnimation.goToAndPlay(0, true);
            }, 1000);
            
            logoAnimation.addEventListener('complete', () => {
                if (!hasMouseInteracted) {
                    lottiePlayTimer = setTimeout(() => {
                        logoAnimation.goToAndPlay(0, true);
                    }, 20000);
                }
            });
        });
    };
    
    const onMouseMove = e => {
        const pos = Math.min(1, Math.max(0, e.pageX/Viewport.width));
        logoAnimation.goToAndStop(Math.round(logoAnimation.totalFrames*pos), true);
        clearTimeout(lottiePlayTimer);
        hasMouseInteracted = true;
    };

    return {
        init,
        destroy
    };
};
