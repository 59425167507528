import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';
import serialize from 'form-serialize';

import { loadGsap } from "../lib/async-bundles";

export default el => {
    const $el = $(el);
    /*
    const $footprint = $el.find('[data-carbon-footprint]');

    let currentFootprint = {
        val: 0
    };
    */
    
    let gsap = null;

    const init = () => {
        const prefs = UserPreferencesHandler.getPrefs();
        //updateFootprint();

        if (prefs.darkMode === 'on') {
            $el.find('input[name="darkMode"]').attr('checked', 'checked');
        }
        if (prefs.simplified === 'on') {
            $el.find('input[name="simplified"]').attr('checked', 'checked');
        }
        if (prefs.lowres === 'on') {
            $el.find('input[name="lowres"]').attr('checked', 'checked');
        }

        $el.find('input[type="checkbox"]').on('change', e => {
            updatePrefs();
            //updateFootprint();
        });

        if (UserPreferencesHandler.shouldAnimate()) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });
            });
        }
    };

    const destroy = () => {
        $el.find('input[type="checkbox"]').off('change');
    };

    const updatePrefs = () => {
        const data = serialize($el.find('form').get(0), { hash: true });
        const prefs = { ...UserPreferencesHandler.defaults(), ...data };

        UserPreferencesHandler.setPrefs(prefs);
    };

    /*
    const updateFootprint = () => {
        const newFootprint = 0.4 + (UserPreferencesHandler.getPrefs().simplified === 'on' ? 0 : 0.15) + (UserPreferencesHandler.getPrefs().lowres === 'on' ? 0 : 1.4);

        if (newFootprint !== currentFootprint.val) {
            if (gsap) {
                gsap.to(currentFootprint, {
                    duration: 1, ease: 'sine.out', val: newFootprint, onUpdate: () => {
                        updateFootprintText(currentFootprint.val);
                    }
                });
            } else {
                updateFootprintText(newFootprint);
            }
        }
    };

    const updateFootprintText = val => {
        $footprint.text((Math.round(val * 100) / 100).toFixed(2) + ' g');
    };
    */

    return {
        init,
        destroy
    };

};
