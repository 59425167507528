import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { loadGsap } from "../lib/async-bundles";

export default el => {
    const $el = $(el);
    const $body = $('body');

    const $infoPanel = $el.find('[data-info-panel]');
    const $prefsPanel = $el.find('[data-prefs-panel]');
    const $pageFooter = $body.find('#bottom');
    const isFrontpage = $body.hasClass('frontpage');

    const HIDE_TIP_THRESHOLD = 350;

    let gsap = null;
    let isInfoPanelVisible = false;
    let isPrefsPanelVisible = false;
    let currentEndOffsetY = 0;
    let isVisible = true;
    let showPanelStartY = 0;

    const init = () => {
        $el.on('click', '[data-prefs-button]', onPrefsToggle);
        $infoPanel.on('click', 'a', onInfoLinkClick);

        if (UserPreferencesHandler.shouldAnimate()) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });

                if (isFrontpage && Viewport.scrollTop < HIDE_TIP_THRESHOLD) {
                    showInfoPanel();
                }
            });
        } else {
            if (isFrontpage && Viewport.scrollTop < HIDE_TIP_THRESHOLD) {
                showInfoPanel();
            }
        }

        updateOffsets();
        
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        
        $prefsPanel.on('click', e => {
            e.stopImmediatePropagation();
        });
        
        $body.on('click', e => {
            if (isPrefsPanelVisible) {
                hidePrefsPanel();
            } 
        });
    };

    const destroy = () => {
        $el.off('click');
        $infoPanel.off('click');
    };
    
    const updateOffsets = () => {
        currentEndOffsetY = $pageFooter.offset().top;
    };
    
    const checkVisibility = () => {
        if (isVisible && (Viewport.scrollTop + Viewport.height > currentEndOffsetY)) {
            hide();
        } else if (!isVisible && (Viewport.scrollTop + Viewport.height < currentEndOffsetY)) {
            show();
        }
    };

    const onResize = () => {
        updateOffsets();
        checkVisibility();
    };
    
    const onScroll = () => {
        if (isFrontpage) {
            if (isInfoPanelVisible && !isPrefsPanelVisible && Viewport.scrollTop > HIDE_TIP_THRESHOLD) {
                hideInfoPanel();
            }

            if (!isInfoPanelVisible && !isPrefsPanelVisible && Viewport.scrollTop < HIDE_TIP_THRESHOLD) {
                showInfoPanel();
            }
        }
        
        if (isPrefsPanelVisible) {
            if (Math.abs(Viewport.scrollTop - showPanelStartY) > 200) {
                hidePrefsPanel();
            }
        }
        
        checkVisibility();
    };

    const onPrefsToggle = e => {
        e.stopImmediatePropagation();
        
        if (!isPrefsPanelVisible) {
            showPrefsPanel();
        } else {
            hidePrefsPanel();
        }
    };

    const showPrefsPanel = () => {
        let delay = 0;

        if (isInfoPanelVisible) {
            if (gsap && UserPreferencesHandler.shouldAnimate()) {
                hideInfoPanel();
                delay = 0.2;
            } else {
                hideInfoPanel();
            }
        }

        $prefsPanel.css({ display: 'block' });

        if (gsap && UserPreferencesHandler.shouldAnimate()) {
            gsap.set($prefsPanel.get(0), { opacity: 0, y: -20 });
            gsap.to($prefsPanel.get(0), { duration: 0.6, delay, opacity: 1, y: 0, ease: 'expo.out' });
        }

        showPanelStartY = Viewport.scrollTop;
        isPrefsPanelVisible = true;
    };

    const hidePrefsPanel = () => {
        if (gsap && UserPreferencesHandler.shouldAnimate()) {
            gsap.to($prefsPanel.get(0), {
                duration: 0.05, opacity: 0, ease: 'sine.out', onComplete: () => {
                    $prefsPanel.css({ display: '' });
                }
            });
        } else {
            $prefsPanel.css({ display: '' });
        }

        isPrefsPanelVisible = false;
    };

    const onInfoLinkClick = e => {
        const $trigger = $(e.triggerTarget);
        const href = $trigger.attr('href');

        if (gsap && UserPreferencesHandler.shouldAnimate() && href !== '') {
            e.preventDefault();
            e.stopImmediatePropagation();

            gsap.to($infoPanel.get(0), {
                duration: 0.05, opacity: 0, ease: 'sine.out', onComplete: () => {
                    location.href = href;
                }
            });
        }
    };

    const showInfoPanel = () => {
        if (gsap && UserPreferencesHandler.shouldAnimate()) {
            gsap.set($infoPanel.get(0), { display: 'block', opacity: 0, y: -20 });
            gsap.to($infoPanel.get(0), { duration: 0.6, delay: 0.6, opacity: 1, y: 0, ease: 'expo.out' });
        } else {
            $infoPanel.css({ display: 'block' });
        }

        isInfoPanelVisible = true;
    };

    const hideInfoPanel = () => {
        if (gsap && UserPreferencesHandler.shouldAnimate()) {
            gsap.killTweensOf($infoPanel.get(0));
            gsap.to($infoPanel.get(0), {
                duration: 0.05, opacity: 0, ease: 'sine.out', onComplete: () => {
                    $infoPanel.css({ display: 'none' });
                }
            });

        } else {
            $infoPanel.css({ display: 'none' });
        }

        isInfoPanelVisible = false;
    };
    
    const show = () => {
        $el.removeClass('translate-y-[calc(100%+72px)]').addClass('translate-y-0%');
        isVisible = true;
    };

    const hide = () => {
        if (isInfoPanelVisible) {
            hideInfoPanel();
        }
        
        if (isPrefsPanelVisible) {
            hidePrefsPanel();
        }
        
        $el.addClass('translate-y-[calc(100%+72px)]').removeClass('translate-y-0%');
        isVisible = false;
    };
    

    return {
        init,
        destroy
    };

};
