const players = [];

const addPlayer = player => {
    players.push(player);
};

const play = player => {
    players.forEach(instance => {
        if (instance !== player) {
            instance.pause();
        }
    });
};

export default {
    addPlayer,
    play
};
