import UserPreferencesHandler from './UserPreferencesHandler';

let pollInterval = 0;
let pollTimeout = 0;

const init = () => {
    if (window.plausible) {
        track();
    } else {
        clearPoll();

        pollInterval = setInterval(() => {
            clearPoll();
            track();
        }, 200);

        pollTimeout = setTimeout(() => {
            clearPoll();
        }, 2000);
    }
};

const clearPoll = () => {
    clearTimeout(pollTimeout);
    clearInterval(pollInterval);
};

const track = () => {
    if (window.plausible) {
        const prefs = UserPreferencesHandler.getPrefs();
        plausible('pageview', { props: { darkMode: prefs.darkMode, lowres: prefs.lowres, simplified: prefs.simplified } });
    }
};

export default {
    init,
    track
};
