/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const USER_PREFERENCES_UPDATED = 'USER_PREFERENCES_UPDATED';
export const FILTER_UPDATED = 'FILTER_UPDATED';
export const FILTER_FORM_UPDATED = 'FILTER_FORM_UPDATED';
export const FLICKITY_DRAG_MOVE = 'FLICKITY_DRAG_MOVE';
export const DOM_UPDATED = 'DOM_UPDATED';
export const REGISTER_POPUP = 'REGISTER_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const UPDATE_GLOBE_ITEMS = 'UPDATE_GLOBE_ITEMS';
export const TRANSITION_OUT = 'TRANSITION_OUT';
export const HOVER_LIST_UPDATED = 'HOVER_LIST_UPDATED';
export const START_LOADER = 'START_LOADER';
export const FINISH_LOADER = 'FINISH_LOADER';
