import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import ViewTracker from "./ViewTracker";

import { DOM_UPDATED, USER_PREFERENCES_UPDATED } from './events';

const def = {
    darkMode: 'off',
    simplified: 'off',
    lowres: 'off'
};

let current = {
    darkMode: 'off',
    simplified: 'off',
    lowres: 'off'
};

let lastUpdatedImageQuality = '';

const defaults = () => {
    return def;
}

const setPrefs = prefs => {
    current = prefs;

    localStorage.darkMode = current.darkMode || 'off';
    localStorage.simplified = current.simplified || 'off';
    localStorage.lowres = current.lowres || 'off';

    handlePrefs();
    ViewTracker.track();
    
    Dispatch.emit(USER_PREFERENCES_UPDATED);
};

const getPrefs = () => {
    return current;
};

const handlePrefs = () => {
    if (localStorage.darkMode === 'on') {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark');
    }

    if (localStorage.simplified === 'on') {
        document.documentElement.classList.add('simplified');
    } else {
        document.documentElement.classList.remove('simplified');
    }

    if (localStorage.lowres !== lastUpdatedImageQuality) {
        handleImages();
    }
};

const handleImages = ($target) => {
    const $markup = $target !== undefined ? $target : $('body');
    const $images = $markup.find('[data-prefs-image]');

    $images.each((img) => {
        const $img = $(img);
        
        if (current.lowres === 'on' && ($img.data('prefs-image')  === '' || $img.data('prefs-image')  === 'high')) {
            $img.removeClass('lazyload', 'lazyloaded', 'lazyautosizes')
                .attr('srcset', null)
                .attr('sizes', null)
                .attr('data-srcset', $img.attr('data-lowres-srcset'))
                .attr('data-prefs-image', 'low')
                .addClass('lazyload');
        } else if (current.lowres !== 'on' && ($img.data('prefs-image')  === '' || $img.data('prefs-image')  === 'low')) {
            $img.removeClass('lazyload', 'lazyloaded', 'lazyautosizes')
                .attr('srcset', null)
                .attr('sizes', null)
                .attr('data-srcset', $img.attr('data-highres-srcset'))
                .attr('data-prefs-image', 'high')
                .addClass('lazyload');
        }
    });
    
    lastUpdatedImageQuality = current.lowres;
};

const shouldAnimate = () => {
    return current.simplified !== 'on';
};

const onDomUpdated = (key, data) => {
    const $markup = data !== undefined && data.$markup !== undefined ? data.$markup : $('body');
    handleImages($markup);
};

const init = () => {
    if (typeof localStorage.darkMode !== 'undefined') {
        current.darkMode = localStorage.darkMode;
    }
    if (typeof localStorage.simplified !== 'undefined') {
        current.simplified = localStorage.simplified;
    }
    if (typeof localStorage.lowres !== 'undefined') {
        current.lowres = localStorage.lowres;
    }
    
    const params = new URLSearchParams(window.location.search);
    
    if (params.get('darkMode') !== null) {
        current.darkMode = localStorage.darkMode = params.get('darkMode') === '1' ? 'on' : 'off';
    }
    if (params.get('simplified') !== null) {
        current.simplified = localStorage.simplified = params.get('simplified') === '1' ? 'on' : 'off';
    }
    if (params.get('lowres') !== null) {
        current.lowres = localStorage.lowres = (params.get('lowres') === '1' ? 'on' : 'off');
    }

    handlePrefs();
    
    Dispatch.on(DOM_UPDATED, onDomUpdated);
};

export default {
    init,
    setPrefs,
    getPrefs,
    shouldAnimate,
    defaults
};
