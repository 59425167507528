import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';
import Dispatch from '../core/Dispatch';
import { loadSuperagent, loadGsap } from "../lib/async-bundles";
import { DOM_UPDATED } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const { currentProjectId, fragmentEndpoint } = props;  
    
    const $btn = $el.find('[data-shuffle-btn]');
    const $relatedWrapper = $el.find('[data-related-projects-wrapper]');
    
    let isLoading = false;
    let superagent = null;
    let gsap = null;

    const init = () => {
        loadSuperagent(loadedSuperagent => {
            superagent = loadedSuperagent;

            $btn.on('click', shuffle);
            
            if (UserPreferencesHandler.shouldAnimate()) {
                loadGsap(({ default: loadedGsap }) => {
                    gsap = loadedGsap;
                    gsap.defaults({ overwrite: 'auto' });
                });
            }
        });
        
    };

    const destroy = () => {

    };
    
    const shuffle = () => {
        if (superagent === null) {
            console.error('Superagent bundle not loaded');
            return;
        }
        
        if (isLoading) {
            return;
        }

        isLoading = true;
        
        if (UserPreferencesHandler.shouldAnimate() && gsap) {
            gsap.to($relatedWrapper.get(0), { duration: 0.1, opacity: 0 });
        } else {
            $relatedWrapper.css({ opacity: 0 });
        }
        
        superagent
            .get(fragmentEndpoint)
            .query({ ajax: 'yes', currentProjectId })
            .set({ 'X-Requested-With': 'XMLHttpRequest' })
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                insertData(text);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
            });
    };
    
    const insertData = html => {
        const $newHtml = $(`<div>${html}</div>`);
        $newHtml.find('noscript').remove();

        const $newResultsList = $newHtml.find('[data-projects-wrapper]');

        if ($newResultsList.length) {
            $relatedWrapper.empty().append($newResultsList.get(0).children);
        }
        
        if (UserPreferencesHandler.shouldAnimate() && gsap) {
            gsap.to($relatedWrapper.get(0), { duration: 0.1, opacity: 1 });
        } else {
            $relatedWrapper.css({ opacity: 1 });
        }
        
        Dispatch.emit(DOM_UPDATED, { $markup: $relatedWrapper });
    };

    return {
        init,
        destroy
    };
};
