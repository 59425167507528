import $ from '../core/Dom';
import Viewport from '../core/Viewport';


export default (el, props) => {
    const $el = $(el);
    const $captions = $el.find('[data-figcaption]');

    const init = () => {
        harmonize();
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };
    
    const onResize = () => {
        harmonize();
    };
    
    const harmonize = () => {
        $captions.css({ height: '' });
        const height = getMaxHeight();
        $captions.css({ height: height });
    };
    
    const getMaxHeight = () => {
        let h = 0;
        
        $captions.each(item => {
            h = Math.max(h, $(item).height());
        });

        return h;
    };
    
    return {
        init,
        destroy
    };
};
