import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { loadGsap } from "./async-bundles";

import { REGISTER_POPUP, CLOSE_POPUP } from './events';

let popups = [];
let initTimeout = null;
let gsap = null;

const getWhitespace = () => {
    return Viewport.breakpoint.size >= 1200 ? 24 : 16;
};

const handlePopups = () => {
    let nextY = 0;

    popups.reverse().forEach(popup => {
        const targetY = nextY + getWhitespace();
        popup.$popup.css({ display: 'block' });

        if (gsap) {
            gsap.set(popup.$popup.get(0), { bottom: -popup.$popup.height() - 25 })
            gsap.to(popup.$popup.get(0), { duration: 0.8, ease: 'quint.out', bottom: targetY })
        } else {
            popup.$popup.css({ bottom: targetY });
        }

        nextY += getWhitespace() + popup.$popup.height();
    });

    popups.reverse();
};

const onRegisterPopup = (e, data) => {
    if (localStorage.getItem('popup_' + data.id) === '1') {
        data.$popup.remove();
        return;
    }

    popups.push(data);

    clearTimeout(initTimeout);
    initTimeout = setTimeout(() => {
        handlePopups();
    }, 500);
};

const onClosePopup = (e, data) => {
    const index = popups.map(x => {
        return x.id;
    }).indexOf(data.id);
    const selectedPopup = popups.splice(index, 1)[0];

    gsap.to(selectedPopup.$popup.get(0), {
        duration: 0.2, opacity: 0, onComplete: () => {
            selectedPopup.$popup.remove();
        }
    });

    localStorage.setItem('popup_' + selectedPopup.id, '1');
};

const init = () => {
    if (UserPreferencesHandler.shouldAnimate()) {
        loadGsap(({ default: loadedGsap }) => {
            gsap = loadedGsap;
            gsap.defaults({ overwrite: 'auto' });
        });
    }

    Dispatch.on(REGISTER_POPUP, onRegisterPopup);
    Dispatch.on(CLOSE_POPUP, onClosePopup);
};

export default {
    init
};
