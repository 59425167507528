import $ from '../core/Dom';
import UserPreferencesHandler from '../lib/UserPreferencesHandler';

import { loadGsap } from "../lib/async-bundles";

export default (el, props) => {
    const $el = $(el);
    
    let gsap = null;
    let $current = null;
    
    const init = () => {
        if (UserPreferencesHandler.shouldAnimate()) {
            loadGsap(({ default: loadedGsap }) => {
                gsap = loadedGsap;
                gsap.defaults({ overwrite: 'auto' });
            });
        }

        $el.on('click', 'button', e => {
            const $button = $(e.triggerTarget);
            toggle($button);
        });
    };

    const destroy = () => {
        $el.off('click');
    };

    const toggle = $button => {
        const $content = $button.parent().next();
        const expanded = $button.attr('aria-expanded') === 'true' || false

        $button.attr('aria-expanded', !expanded);

        if (expanded) {
            collapse($content);
            $current = null;
        } else {
            expand($content);
            $current = $button;
        }
    };

    const collapse = $content => {
        if (UserPreferencesHandler.shouldAnimate() && gsap) {
            $content.css({ height: '' });
            const targetHeight = $content.height();
            $content.css({ height: 'auto' });
            
            gsap.to($content.get(0), {
                duration: 0.4, height: targetHeight, ease: 'quint.inOut', onComplete: () => {
                    $content.css({ height: '' });
                }
            });
        } else {
            $content.css({ height: '' });
        }
        
        $el.removeClass('accordion-has-selected');
        $content.parent().removeClass('accordion-selected-item');
    };

    const expand = $content => {
        if ($current !== null) {
            toggle($current)
        }
        
        if (UserPreferencesHandler.shouldAnimate() && gsap) {
            gsap.to($content.get(0), { duration: 0.4, height: 'auto', ease: 'quint.inOut' });
        } else {
            $content.attr('hidden', null);
            $content.css({ height: 'auto', overflow: 'hidden' });
        }

        $el.addClass('accordion-has-selected');
        $content.parent().addClass('accordion-selected-item');
    };

    return {
        init,
        destroy
    };
};
