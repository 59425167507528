import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { REGISTER_POPUP, CLOSE_POPUP } from '../lib/events'

export default (el, props) => {
    const $el = $(el);
    
    const { id } = props;

    const init = () => {
        $el.on('click', '[data-btn-close]', onClose);
        Dispatch.emit(REGISTER_POPUP, { id: id, $popup: $el });
    };

    const destroy = () => {
        $el.off('click', '[data-btn-close]', onClose);
    };
    
    const onClose = () => {
        Dispatch.emit(CLOSE_POPUP, { id: id });
    };

    return {
        init,
        destroy
    };

};
